import { Box } from "@mui/joy";

import { Link as RouterLink, useNavigate, useParams } from "react-router-dom";
import { useContext, useEffect } from "react";
import { APICheckEmail, APICheckInvoice } from "../api";
import { LayoutContext } from "../context";




export default function PayPage() {
    const navigate = useNavigate();
    const { uuid } = useParams(); // Извлекаем токен из URL
    const { setModalSuccessPay } = useContext(LayoutContext);
    

    useEffect(() => {
        // Првоеряем платеж
        APICheckInvoice(uuid).then((data) => {
            if (data?.status == 'ok') {
                navigate("/");
                setModalSuccessPay(true);
                return
            }
            navigate("/");
            alert(data?.response?.data?.message);
        })
    }, [uuid]);

    return (
        <>
            <Box>
                Оплата
            </Box>
        </>
    );
}