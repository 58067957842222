import { Button, Link, FormControl, FormLabel, Input, Modal, ModalClose, ModalDialog, ModalOverflow, Stack, Typography } from "@mui/joy";
import { useContext, useState } from "react";
import { LayoutContext } from "../../context";
import { motion, AnimatePresence } from "framer-motion";
import { Link as RouterLink, useLocation, useNavigate } from "react-router-dom";


import { APIAuthUser } from "../../api";
import Cookies from 'js-cookie'



export default function ModalAuth() {
    const navigate = useNavigate();
    const { modalAuth, setModalAuth, setAccount, setModalResetPassword } = useContext(LayoutContext);
    const [ loading, setLoading ] = useState(false);

    const [ error, setError ] = useState();
    // const [ phone, setPhone ] = useState();
    const [ email, setEmail ] = useState();
    const [ pwd, setPwd ] = useState();


    async function Login(e) {
        e.preventDefault();
        // Генерация JWT
        // const secretKey = 'cSYGzwC8gzfAMiXV5c1fkBnVoVzKzKc2WNwrms08'; // Публичный ключ (можно получить от сервера)
        // const secret = new TextEncoder().encode(secretKey); // Преобразуем секрет в байты
        // const payload = { phone: phone, password: pwd };
        // // Генерация токена
        // const token = await new SignJWT(payload)
        // .setProtectedHeader({ alg: 'HS256' })  // Алгоритм подписи
        // .sign(secret); // Подпись токена
        // console.log(token);
        // Получаем публичный ключ

        setLoading(true);
        APIAuthUser(email, pwd).then((data) => {
            if (data?.status == 'ok') {
                // Сохранение токена в cookie
                Cookies.set('authToken', data.token, { expires: 7, secure: true, sameSite: 'Strict' });
                console.log("Auth", data);
                setAccount(data.account);
                navigate("/lk");
                setModalAuth(false);
            }
            else if (data?.response?.data?.status == 'info') {
                setError(data?.response?.data?.message);
            }
            setLoading(false);
        })
        
        // try {
        //     const response = await axios.post(API_URL + '/account/login/', { token });
        //     console.log('Ответ сервера:', response.data);
        // } catch (error) {
        //     console.error('Ошибка авторизации:', error);
        // }

        // setModalAuth(false);
        // navigate("/lk/unit");
    }

    function toResetPwd() {
        setModalAuth(false);
        setModalResetPassword(true);
    }


    return (
        <AnimatePresence>
            {
                modalAuth && (
                    <Modal
                        open={modalAuth}
                        onClose={() => setModalAuth(false)}
                        slotProps={{
                            backdrop: {
                                component: motion.div,
                                initial: { opacity: 0, backdropFilter: 'blur(0px)' },
                                animate: { opacity: 1, backdropFilter: 'blur(8px)' },
                                exit: { opacity: 0, backdropFilter: 'blur(0px)' },
                                transition: { duration: 0.3, ease: "easeInOut" },
                            }
                        }}
                    >
                        <ModalOverflow sx={{ height: 'auto' }}>
                            <ModalDialog
                                size="lg"
                                component={motion.div}
                                initial={{ opacity: 0, y: -10, scale: 0.98 }}
                                animate={{ opacity: 1, y: 0, scale: 1 }}
                                exit={{ opacity: 0, y: 10, scale: 0.98 }}
                                transition={{ duration: 0.2, ease: "easeInOut" }}
                            >
                                <ModalClose/>
                                <Typography sx={{ textAlign: 'center', mb: 0 }} level="h3">
                                    Авторизация
                                </Typography>
                                { error != "" &&
                                    <Typography sx={{ textAlign: 'center' }} color="danger" level="title-sm">
                                        {error}
                                    </Typography>
                                }
                                <form
                                    onSubmit={Login}
                                >
                                    <Stack spacing={2}>
                                        <FormControl>
                                            <FormLabel>Почта*</FormLabel>
                                            {/* <InputMask mask="+7 (999) 999-99-99" value={phone} onChange={(e) => setPhone(e.target.value)}>
                                                {(inputProps) => <Input {...inputProps} type="tel" size="sm" required name="phone" disableUnderline placeholder="+7 (XXX) XXX-XX-XX" />}
                                            </InputMask> */}
                                            <Input value={email} onChange={(e) => setEmail(e.target.value)} size="sm" required type="email" placeholder="test@gmail.com" />
                                        </FormControl>
                                        <FormControl>
                                            <FormLabel>Пароль*</FormLabel>
                                            <Input value={pwd} onChange={(e) => setPwd(e.target.value)} size="sm" required type="password" placeholder="***" />
                                        </FormControl>
                                        <Stack spacing={1}>
                                            <Button loading={loading} variant="solid" color="success" type="submit">
                                                Войти
                                            </Button>
                                            <Typography level="body-sm" sx={{ textAlign: 'center' }}>
                                                Забыл <Link onClick={toResetPwd} color="primary">пароль</Link>?
                                            </Typography>
                                        </Stack>
                                    </Stack>
                                </form>
                            </ModalDialog>
                        </ModalOverflow>
                    </Modal>
                )
            }
        </AnimatePresence>
    );
}