import { Avatar, Box, Button, Checkbox, Chip, FormControl, FormLabel, IconButton, Input, Option, Select, Sheet, Stack, Table, Tooltip, Typography } from "@mui/joy";
import { useContext, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import ItemTableUnitEconomic from "./ItemTableUnitEconomic";

import DeleteForeverRoundedIcon from '@mui/icons-material/DeleteForeverRounded';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { APIDeleteUnit, APIGetUnitWarehouse } from "../../api";
import { LayoutContext } from "../../context";
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';





export default function ToolTipTable({ title, description }) {
  const [openTooltip, setOpenTooltip] = useState(false);
    
  return (
    <Stack direction={'row'} sx={{ alignItems: "center", justifyContent: "flex-start" }}>
      {title}
      <Tooltip arrow 
        open={openTooltip}
        // onClose={() => setOpenTooltip(false)}
        color="primary" 
        size="md"
        title={
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              maxWidth: 320,
              justifyContent: 'center',
              p: 1,
            }}
          >
            {description}
          </Box>
        }
      >
        <IconButton sx={{ ml: 0 }} color={openTooltip ? "danger" : "neutral"} variant={openTooltip ? "solid" : "soft"} size={'sm'} onClick={() => setOpenTooltip(!openTooltip)} >
          { openTooltip ?
            <CloseRoundedIcon />
            :
            <InfoOutlinedIcon/>
          }
        </IconButton>
      </Tooltip>
    </Stack>
  );
}