import { Box, Button, Modal, ModalClose, ModalDialog, ModalOverflow, Stack, Typography } from "@mui/joy";
import { useContext, useState } from "react";
import { LayoutContext } from "../../context";
import { motion, AnimatePresence } from "framer-motion";
import { useNavigate } from "react-router-dom";


export default function ModalSendEmail() {
    const { modalSendEmail, setModalSendEmail } = useContext(LayoutContext);

    return (
        <AnimatePresence>
            {
                modalSendEmail && (
                    <Modal
                        open={modalSendEmail}
                        onClose={() => setModalSendEmail(false)}
                        slotProps={{
                            backdrop: {
                                component: motion.div,
                                initial: { opacity: 0, backdropFilter: 'blur(0px)' },
                                animate: { opacity: 1, backdropFilter: 'blur(8px)' },
                                exit: { opacity: 0, backdropFilter: 'blur(0px)' },
                                transition: { duration: 0.3, ease: "easeInOut" },
                            }
                        }}
                    >
                        <ModalOverflow sx={{ height: 'auto' }}>
                            <ModalDialog
                                size="lg"
                                component={motion.div}
                                initial={{ opacity: 0, y: -10, scale: 0.98 }}
                                animate={{ opacity: 1, y: 0, scale: 1 }}
                                exit={{ opacity: 0, y: 10, scale: 0.98 }}
                                transition={{ duration: 0.2, ease: "easeInOut" }}
                                sx={{ alignItems: "center", maxWidth: 400, gap: 0 }}
                            >
                                <ModalClose />

                                <Box sx={{ mt: 2 }}>
                                    <img src="/gmail.gif" width={120} style={{ "objectFit": 'cover' }}/>
                                </Box>

                                <Stack spacing={2}>
                                    <Typography sx={{ textAlign: 'center' }} level="h3">
                                        Письмо отправлено на почту
                                    </Typography>
                                    <Box>
                                        <Typography sx={{ textAlign: 'center' }} level="body-sm">
                                            Спасибо за регистрацию в TrendLab! На указанный вами адрес электронной почты отправлено письмо для подтверждения регистрации.
                                        </Typography>
                                        <Typography sx={{ textAlign: 'center', mt: 1 }} color="primary" level="body-sm">
                                            Если письмо не пришло, пожалуйста, проверьте папку «Спам».
                                        </Typography>
                                    </Box>
                                    <Box>
                                        <Button onClick={() => setModalSendEmail(false)} sx={{ width: '100%', mt: 2 }} size="lg" variant="solid" color="primary" type="submit">
                                            Отлично!
                                        </Button>
                                    </Box>
                                </Stack>
                            </ModalDialog>
                        </ModalOverflow>
                    </Modal>
                )
            }
        </AnimatePresence>
    );
}