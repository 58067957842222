import axios from 'axios';
import Cookies from 'js-cookie';
import { importPKCS8, SignJWT } from 'jose';

export const API_URL = 'https://api.trendlab.pro'
// export const API_URL = 'http://127.0.0.1:8000'


export const privateKeyPEM = `-----BEGIN PRIVATE KEY-----
MIIEvQIBADANBgkqhkiG9w0BAQEFAASCBKcwggSjAgEAAoIBAQD26hRgr0mYalkz
AxINL9jP03nRA2EzNAOjsbuofpZJvyqmINWB9TpqUnYZYatmF9e9jJklkSGqbqtD
9eyn4VhG8Qp+73HOxYJXIgAqyhf4iaO0IOQ3GcVsAKjlvVCf0ZLm+ycoqDYAuSKr
5XK0vSqAr7Dih76JZaHNTkLgaGt6dvUnWQFr9LIQUTxNOZTz3TOdQM/WO9p6gL7P
XHEKRUiLQfNXkyd8dPbhPcblP0u6SxnYNhvJTIItWWSgfEydPSG4AARc9dSVIVVC
76J7mftlyMHc6eiw/quqIoSgxjD9AYxHjd3R3ACC270uxojcNQXqFno2ytGNkFNM
Qa6jfYZVAgMBAAECggEAE0Vjv8QOj2nFenjD4OmaF2Ze/JQx8ugdXJpTliwDGNBl
yL+JSnJUU9YJx6Na2EYGHZIqpcb1BLNMhKpeBSBOXoMTmaKglE3QBXLu5mgNhOVh
NH7YsQA+61YF2KB0+XRG7eYuQTsXs+i/LwFieX7GIXMU8Ck+EsUu4rI4AAHGWbFl
48aFoVSZ1z6IK/kO+SOsWJZK2iv0PNT1ABHi9umtgtE0BQBSyJP/pOeO1zbq0oYj
VLmM6zwABKcZsHVwSv65zaJm2QTlUqjyWt6kdzoi7yO5xfYWeMY0LnOMq6DWoSRC
xTOrcoOrB+fYNOAFSYbvs3yyCJosTqmODzcbLveBxQKBgQD9UzdQNrnDcCAGpnn6
3k859EdUl1Qdth5GA0EsGR3j9GTRfJNm1AazAWyyftUtZ0b4hXhun0ph1f+XEp16
UQU3trgWBdN+Y+lrTF84TgJDEBqNip7LOZUUuhnxhvUCbDD+cWG4ZlJmqFnUUI6w
dfKAn3cBclKV0+00glJnKrHQYwKBgQD5hYjFyRAhu8Ag2xaKG9QRxUUu8rDDYmEU
Mpy+eIECXl93uqZ55MzyTKm0N65VTlBDA1G9I/TZyigv+OslW26telbFertRqPjN
MbjWY+pkif+jZUTWWpm9JGXo4C92CVZ9P02YrGUjW/CphSt5oSf7vGoLJUD73vsF
IA99nv+f5wKBgCIvdKtydycUnn3HQXbFQVPEz0tvEH7h8Lvp/IWC7jGw7VoHwayC
uFS3wH9l9kamRUGMYXAsNtF1G4S7klzLSjT2h2kRaDUKLpm4OLFUdaGvLqYjKsKq
BqBBbPbXC4FEMGhlzJYh2MTZuyWXqWnOwObynhCn6NUnQD9xxsT9UfmZAoGARdOc
ptzRat9DsO4erJ+FBUQTTEQWO1c4m5JeUt8eGy+Vv8e39ryUrenOQ+ShILY9t8Mi
CLAbQSdVf7dE9rtTibCGv9TMvtluZt3XQAfN/DyyEWOxk9re0xNwgW7bWUjbr0kb
yfHUi8Sa4JWnyO3UZpofgub1/IY18QU70IfCOVkCgYEAmEtd94vQIfx47a/jLkf6
W5M229bQhn2UFYPE3go/xZCR6XiIy1130W1JpBrLvgWbBKSusMkxMUzhuy9d0SU4
odN4xlIvVYwpuRIz3jyZX4SkFyPdz3iL1cyOjHyZ0MwHYmkKjT/j7opovQqhgm6o
Wrg6vWQb/Buw85ZcYoOY1Y8=
-----END PRIVATE KEY-----`;



const API_GET_POSTS = '/api-bloggers/get-posts-filters/'
// Запрос постов
export const APIGetPosts = async (page) => {
  try {
    const response = await axios.get(API_URL + API_GET_POSTS, {
      params: { page }
    });
    return response.data;
  } catch (error) {
    console.log(error);
    if (error.code === 'ECONNABORTED') {
      console.error('Запрос превысил лимит ожидания.');
      return error;
    } 
    if (error.code === 'ERR_NETWORK') {
      console.error('Ошибка сети.');
      return error;
    } else {
      console.error('Произошла ошибка:', error.message);
      return error;
    }
  }
};


const API_POST_LOGIN = '/api/login/'
// Запрос авторизации
export const APIPostLogin = async () => {
  try {
    const response = await axios.get(API_URL + API_POST_LOGIN);
    return response.data;
  } catch (error) {
    console.log(error);
    if (error.code === 'ECONNABORTED') {
      console.error('Запрос превысил лимит ожидания.');
      return error;
    } 
    if (error.code === 'ERR_NETWORK') {
      console.error('Ошибка сети.');
      return error;
    } else {
      console.error('Произошла ошибка:', error.message);
      return error;
    }
  }
};



const API_AUTH_USER = '/account/login/'
// Запрос авторизации
export const APIAuthUser = async (email, pwd) => {
  // Импортируем приватный ключ как CryptoKey
  const privateKey = await importPKCS8(privateKeyPEM, 'RS256');
  // Генерация токена
  const token = await new SignJWT({ email: email, password: pwd })
  .setProtectedHeader({ alg: 'RS256' })  // Алгоритм подписи
  // .setIssuedAt() // Устанавливаем время выпуска
  .sign(privateKey); // Подпись токена
  // console.log(token);

  try {
    const response = await axios.post(API_URL + API_AUTH_USER, { token });
    return response.data;
  } catch (error) {
    console.log(error);
    if (error.code === 'ECONNABORTED') {
      console.error('Запрос превысил лимит ожидания.');
      return error;
    } 
    if (error.code === 'ERR_NETWORK') {
      console.error('Ошибка сети.');
      return error;
    } else {
      console.error('Произошла ошибка:', error.message);
      return error;
    }
  }
};




const API_GET_USER = '/account/get-user/'
// Запрос получить пользователя
export const APIGetUser = async () => {

  try {
    const authToken = Cookies.get('authToken');
    const response = await axios.get(API_URL + API_GET_USER, {
      headers: {
        Authorization: authToken,  // Передача токена в заголовке
      }
    });
    return response.data;
  } catch (error) {
    console.log(error);
    if (error.code === 'ECONNABORTED') {
      console.error('Запрос превысил лимит ожидания.');
      return error;
    } 
    if (error.code === 'ERR_NETWORK') {
      console.error('Ошибка сети.');
      return error;
    } else {
      console.error('Произошла ошибка:', error.message);
      return error;
    }
  }
};



const API_GET_RATES = '/account/rates'
// Запрос списка тарифов
export const APIGetRates = async () => {
  try {
    const response = await axios.get(API_URL + API_GET_RATES);
    return response.data;
  } catch (error) {
    console.log(error);
    if (error.code === 'ECONNABORTED') {
      console.error('Запрос превысил лимит ожидания.');
      return error;
    } 
    if (error.code === 'ERR_NETWORK') {
      console.error('Ошибка сети.');
      return error;
    } else {
      console.error('Произошла ошибка:', error.message);
      return error;
    }
  }
};



const API_CREATE_INVOICE = '/account/create-pay'
// Запрос создания платежа
export const APICreateInvoice = async (rate, token, token_v) => {
  try {
    var params = {
      rate: rate
    }
    if (token) params.token = token
    else if (token_v) params.token_v = token_v
    const response = await axios.post(API_URL + API_CREATE_INVOICE, params);
    return response.data;
  } catch (error) {
    console.log(error);
    if (error.code === 'ECONNABORTED') {
      console.error('Запрос превысил лимит ожидания.');
      return error;
    } 
    if (error.code === 'ERR_NETWORK') {
      console.error('Ошибка сети.');
      return error;
    } else {
      console.error('Произошла ошибка:', error.message);
      return error;
    }
  }
};



const API_CHECK_INVOICE = '/account/check-pay'
// Запрос проверка платежа
export const APICheckInvoice = async (uuid) => {
  try {
    const response = await axios.get(API_URL + API_CHECK_INVOICE, { params: { uuid: uuid } });
    return response.data;
  } catch (error) {
    console.log(error);
    if (error.code === 'ECONNABORTED') {
      console.error('Запрос превысил лимит ожидания.');
      return error;
    } 
    if (error.code === 'ERR_NETWORK') {
      console.error('Ошибка сети.');
      return error;
    } else {
      console.error('Произошла ошибка:', error.message);
      return error;
    }
  }
};


const API_REGISTRATION = '/account/registration/'
// Запрос регистрации пользователя
export const APIRegistration = async (data) => {
  try {
    const response = await axios.post(API_URL + API_REGISTRATION, data);
    return response.data;
  } catch (error) {
    console.log(error);
    if (error.code === 'ECONNABORTED') {
      console.error('Запрос превысил лимит ожидания.');
      return error;
    } 
    if (error.code === 'ERR_NETWORK') {
      console.error('Ошибка сети.');
      return error;
    } else {
      console.error('Произошла ошибка:', error.message);
      return error;
    }
  }
};


const API_CHECK_EMAIL = '/account/check-email/'
// Запрос проверки токена подтверждения почты
export const APICheckEmail = async (token) => {
  try {
    const response = await axios.get(API_URL + API_CHECK_EMAIL + token);
    return response.data;
  } catch (error) {
    console.log(error);
    if (error.code === 'ECONNABORTED') {
      console.error('Запрос превысил лимит ожидания.');
      return error;
    } 
    if (error.code === 'ERR_NETWORK') {
      console.error('Ошибка сети.');
      return error;
    } else {
      console.error('Произошла ошибка:', error.message);
      return error;
    }
  }
};



const API_RESEND_EMAIL = '/account/check-email/'
// Запрос отправки повторно
export const APIResendEmail = async (token) => {
  try {
    const response = await axios.post(API_URL + API_RESEND_EMAIL + token);
    return response.data;
  } catch (error) {
    console.log(error);
    if (error.code === 'ECONNABORTED') {
      console.error('Запрос превысил лимит ожидания.');
      return error;
    } 
    if (error.code === 'ERR_NETWORK') {
      console.error('Ошибка сети.');
      return error;
    } else {
      console.error('Произошла ошибка:', error.message);
      return error;
    }
  }
};



const API_RESET_PWD = '/account/reset-password/'
// Запрос отправки повторно
export const APIResetPassword = async (email) => {
  // Импортируем приватный ключ как CryptoKey
  const privateKey = await importPKCS8(privateKeyPEM, 'RS256');
  // Генерация токена
  const token = await new SignJWT({ email: email })
  .setProtectedHeader({ alg: 'RS256' })  // Алгоритм подписи
  // .setIssuedAt() // Устанавливаем время выпуска
  .sign(privateKey); // Подпись токена
  // console.log(token);

  try {
    const response = await axios.post(API_URL + API_RESET_PWD, { token });
    return response.data;
  } catch (error) {
    console.log(error);
    if (error.code === 'ECONNABORTED') {
      console.error('Запрос превысил лимит ожидания.');
      return error;
    } 
    if (error.code === 'ERR_NETWORK') {
      console.error('Ошибка сети.');
      return error;
    } else {
      console.error('Произошла ошибка:', error.message);
      return error;
    }
  }
};



const API_CHECK_RESET_PWD = '/account/check-reset-password/'
// Запрос проверки нового пароля
export const APICheckResendPwd = async (token, pwd) => {
  // Импортируем приватный ключ как CryptoKey
  const privateKey = await importPKCS8(privateKeyPEM, 'RS256');
  // Генерация токена
  const token_data = await new SignJWT({ password: pwd })
  .setProtectedHeader({ alg: 'RS256' })  // Алгоритм подписи
  // .setIssuedAt() // Устанавливаем время выпуска
  .sign(privateKey); // Подпись токена
  // console.log(token);

  try {
    const response = await axios.post(API_URL + API_CHECK_RESET_PWD, { token_reset: token, token: token_data });
    return response.data;
  } catch (error) {
    console.log(error);
    if (error.code === 'ECONNABORTED') {
      console.error('Запрос превысил лимит ожидания.');
      return error;
    } 
    if (error.code === 'ERR_NETWORK') {
      console.error('Ошибка сети.');
      return error;
    } else {
      console.error('Произошла ошибка:', error.message);
      return error;
    }
  }
};






const API_GET_UNIT_WAREHOUSE = '/unit/unit-warehouse/'
// Запрос получить данных юнит экономики
export const APIGetUnitWarehouse = async () => {
  try {
    const authToken = Cookies.get('authToken');
    const response = await axios.get(API_URL + API_GET_UNIT_WAREHOUSE, {
      headers: {
        Authorization: authToken,  // Передача токена в заголовке
      }
    });
    return response.data;
  } catch (error) {
    console.log(error);
    if (error.code === 'ECONNABORTED') {
      console.error('Запрос превысил лимит ожидания.');
    } 
    if (error.code === 'ERR_NETWORK') {
      console.error('Ошибка сети.');
    } else {
      console.error('Произошла ошибка:', error.message);
    }
    return undefined;
  }
};



const API_CREATE_UNIT = '/unit/create-unit/'
// Запрос создания юнит экономики
export const APICreateUnit = async (data) => {
  try {
    const authToken = Cookies.get('authToken');
    const response = await axios.post(API_URL + API_CREATE_UNIT, data, {
      headers: {
        Authorization: authToken,  // Передача токена в заголовке
      }
    });
    return response.data;
  } catch (error) {
    console.log(error);
    if (error.code === 'ECONNABORTED') {
      console.error('Запрос превысил лимит ожидания.');
    } 
    if (error.code === 'ERR_NETWORK') {
      console.error('Ошибка сети.');
    } else {
      console.error('Произошла ошибка:', error.message);
    }
    return undefined;
  }
};



const API_DELETE_UNIT = '/unit/delete-unit/'
// Запрос удаление юнит экономики
export const APIDeleteUnit = async (id) => {
  try {
    const authToken = Cookies.get('authToken');
    const response = await axios.delete(API_URL + API_DELETE_UNIT + `${id}/`, {
      headers: {
        Authorization: authToken,  // Передача токена в заголовке
      }
    });
    return response.data;
  } catch (error) {
    console.log(error);
    if (error.code === 'ECONNABORTED') {
      console.error('Запрос превысил лимит ожидания.');
    } 
    if (error.code === 'ERR_NETWORK') {
      console.error('Ошибка сети.');
    } else {
      console.error('Произошла ошибка:', error.message);
    }
    return undefined;
  }
};




const API_EXPORT_CALCULATIONS = '/unit/export-calculations/'
// Запрос экспортирования таблицы
export const APIGetExportCalculations = async (selectedIds) => {
  try {
    const authToken = Cookies.get('authToken');
    const response = await axios.post(API_URL + API_EXPORT_CALCULATIONS, {
      ids: selectedIds,
    }, {
      responseType: 'blob', // Указываем, что ответ будет в виде файла
      headers: {
        Authorization: authToken,  // Передача токена в заголовке
      }
    });
    return response;
  } catch (error) {
    console.log(error);
    if (error.code === 'ECONNABORTED') {
      console.error('Запрос превысил лимит ожидания.');
    } 
    if (error.code === 'ERR_NETWORK') {
      console.error('Ошибка сети.');
    } else {
      console.error('Произошла ошибка:', error.message);
    }
    return undefined;
  }
};






const API_GET_LIST_SEARCH_ARTIC = '/articles/get-search-artic/';
// Запрос получения списка поисковых элементов
export const APIGetListSearchArtic = async () => {
  try {
    const authToken = Cookies.get('authToken');
    const response = await axios.get(API_URL + API_GET_LIST_SEARCH_ARTIC, {
      headers: {
        Authorization: authToken,  // Передача токена в заголовке
      }
    });
    return response.data;
  } catch (error) {
    console.log(error);
    if (error.code === 'ECONNABORTED') {
      console.error('Запрос превысил лимит ожидания.');
      return error;
    } 
    if (error.code === 'ERR_NETWORK') {
      console.error('Ошибка сети.');
      return error;
    } else {
      console.error('Произошла ошибка:', error.message);
      return error;
    }
  }
};




const API_POST_SEARCH_ARTIC = '/articles/post-search-artic/';
// Запрос создание поисковых элементов
export const APIPostSearchArtic = async (key, list_select_social) => {
  try {
    const authToken = Cookies.get('authToken');
    const response = await axios.post(API_URL + API_POST_SEARCH_ARTIC, { key: key, list_select_social: list_select_social }, {
      headers: {
        Authorization: authToken,  // Передача токена в заголовке
      }
    });
    return response.data;
  } catch (error) {
    console.log(error);
    if (error.code === 'ECONNABORTED') {
      console.error('Запрос превысил лимит ожидания.');
      return error;
    } 
    if (error.code === 'ERR_NETWORK') {
      console.error('Ошибка сети.');
      return error;
    } else {
      console.error('Произошла ошибка:', error.message);
      return error;
    }
  }
};