import { Box, Button, Card, CardContent, Checkbox, DialogContent, DialogTitle, Divider, FormControl, FormLabel, Grid, IconButton, Input, Modal, ModalClose, ModalDialog, ModalOverflow, Radio, Sheet, Stack, Typography } from "@mui/joy";
import { useContext, useEffect, useMemo, useState } from "react";
import { LayoutContext } from "../../context";
import { motion, AnimatePresence } from "framer-motion";
import InputMask from 'react-input-mask';
import { useLocation, useNavigate } from "react-router-dom";
import TwoWheelerRoundedIcon from '@mui/icons-material/TwoWheelerRounded';

import Cookies from 'js-cookie';
import BarChartRoundedIcon from '@mui/icons-material/BarChartRounded';
import MonochromePhotosRoundedIcon from '@mui/icons-material/MonochromePhotosRounded';
import PageviewRoundedIcon from '@mui/icons-material/PageviewRounded';
import ClearRoundedIcon from '@mui/icons-material/ClearRounded';
import { API_URL, APIAuthUser, APICreateInvoice, APIGetRates, privateKeyPEM } from "../../api";
import { importPKCS8, SignJWT } from "jose";


export default function ModalRate() {
    const navigate = useNavigate();
    const { account, modalRate, setModalRate, setModalSuccessPay, setAccount } = useContext(LayoutContext);

    const [ paymentUuid, setPaymentUuid ] = useState(undefined);

    const [ error, setError ] = useState("");
    const [ email, setEmail ] = useState();
    const [ pwd, setPwd ] = useState();
    const [ selectRate, setSelectRate ] = useState(undefined);

    const [ rates, setRates ] = useState([]);

    useEffect(() => {
        APIGetRates().then((data) => {
            if (data) {
                setRates(data);
                console.log("Rates", data);
            }
        })
    }, []);




    useEffect(() => {
        if (paymentUuid) {
            // Подключаемся к SSE-эндпоинту с параметром UUID
            const eventSource = new EventSource(API_URL + `/account/sse/payment-status/?uuid=${paymentUuid}`);

            eventSource.onmessage = (event) => {
                const data = JSON.parse(event.data);
                if (data.status) {
                    console.log("ОПЛАТА", data.status);
                    setModalRate(false);
                    setModalSuccessPay(true);

                    APIAuthUser(email, pwd).then((data) => {
                        if (data?.status == 'ok') {
                            // Сохранение токена в cookie
                            Cookies.set('authToken', data.token, { expires: 7, secure: true, sameSite: 'Strict' });
                            console.log("Auth", data);
                            setAccount(data.account);
                        }
                    })

                    eventSource.close(); // Закрываем SSE, если статус "succeeded"
                }
            };

            eventSource.onerror = () => {
                console.error("Ошибка SSE-соединения");
                eventSource.close();
            };

            return () => eventSource.close(); // Закрываем SSE при размонтировании компонента
        }
    }, [paymentUuid]);


    // Генерация оплаты 
    async function submitFormPay(e) {
        e.preventDefault();
        // setModalRate(false);
        // navigate("/lk/unit");

        var token = null;
        var token_v = null;
        if (Cookies.get('authToken')) {
            token_v = Cookies.get('authToken');
        }
        else {
            // Импортируем приватный ключ как CryptoKey
            const privateKey = await importPKCS8(privateKeyPEM, 'RS256');
            // Генерация токена
            token = await new SignJWT({ email: email, password: pwd })
            .setProtectedHeader({ alg: 'RS256' })  // Алгоритм подписи
            // .setIssuedAt() // Устанавливаем время выпуска
            .sign(privateKey); // Подпись токена
            // console.log(token);
        }
        

        APICreateInvoice(selectRate, token, token_v).then((data) => {
            if (data?.confirmation_url) {
                console.log("Invoice", data);
                setPaymentUuid(data?.uuid);
                // Открываем новое окно для оплаты
                window.open(data?.confirmation_url, "_blank");
                return
            }
            if (data?.status == 'ok') {
                setModalRate(false);
                // Cookies.set('authToken', data.token, { expires: 7, secure: true, sameSite: 'Strict' });
                setAccount(data?.account);
                navigate("/lk");
            }
            if (data?.response?.data?.status == 'account') {
                setAccount({});
            }
            setError(data?.response?.data?.message);
        })
    }


    // const rates = [
    //     {
    //         id: 1,
    //         name: 'Unit-экономика',
    //         price: 350,
    //         icon: <BarChartRoundedIcon sx={{ fontSize: 'xl2' }} />,
    //     },
    //     {
    //         id: 2,
    //         name: 'Поиск по артикулу',
    //         price: 600,
    //         icon: <MonochromePhotosRoundedIcon sx={{ fontSize: 'xl2' }} />,
    //     },
    //     {
    //         id: 3,
    //         name: 'База блогеров',
    //         price: 400,
    //         icon: <PageviewRoundedIcon sx={{ fontSize: 'xl2' }} />,
    //     }
    // ]

    // const days = [
    //     {
    //         id: 1,
    //         sp: 1,
    //         name: 'На 2 дня',
    //     },
    //     {
    //         id: 2,
    //         sp: 2,
    //         name: 'На 7 дней',
    //     },
    //     {
    //         id: 3,
    //         sp: 5,
    //         name: 'На 30 дней',
    //     }
    // ]

    const totalCheck = useMemo(() => {
        const obj = rates.find(obj => obj.id === selectRate);
        return (
            <Stack spacing={2} direction={"row"} sx={{ alignItems: "center", justifyContent: "space-between" }}>
                <Box>
                    <Typography level="title-sm">
                        {obj?.name}
                    </Typography>
                    <Typography level="body-sm">
                        Доступ к функции "{obj?.name}"
                    </Typography>
                    <Typography level="body-sm" color="primary" sx={{ fontWeight: 'xl' }}>
                        {obj?.price} ₽
                    </Typography>
                </Box>
                <IconButton size="sm"
                    onClick={(event) => setSelectRate(undefined)}
                >
                    <ClearRoundedIcon/>
                </IconButton>
            </Stack>
        )
    }, [selectRate])

    return (
        <AnimatePresence>
            {
                modalRate && (
                    <Modal
                        open={modalRate}
                        onClose={() => setModalRate(false)}
                        slotProps={{
                            backdrop: {
                                component: motion.div,
                                initial: { opacity: 0, backdropFilter: 'blur(0px)' },
                                animate: { opacity: 1, backdropFilter: 'blur(8px)' },
                                exit: { opacity: 0, backdropFilter: 'blur(0px)' },
                                transition: { duration: 0.3, ease: "easeInOut" },
                            }
                        }}
                    >
                        <ModalOverflow sx={{ height: 'auto' }}>
                            <ModalDialog
                                size="lg"
                                component={motion.div}
                                initial={{ opacity: 0, y: -10, scale: 0.98 }}
                                animate={{ opacity: 1, y: 0, scale: 1 }}
                                exit={{ opacity: 0, y: 10, scale: 0.98 }}
                                transition={{ duration: 0.2, ease: "easeInOut" }}
                                sx={{
                                    width: {
                                        xs: '90%',
                                        sm: "90%",
                                        smmd: "80%",
                                        lg: "100%"
                                    },
                                    maxWidth: 1000
                                }}
                            >
                                <ModalClose />
                                <Typography sx={{ textAlign: 'center', mb: 3 }} level="h2">
                                    Конструктор тарифа
                                </Typography>
                                <form
                                    onSubmit={submitFormPay}
                                >
                                    <Stack spacing={2} direction={{ xs: "column", md: "row"}}>
                                        <Stack spacing={2} direction={"row"}>
                                            {/* <Sheet
                                                variant="soft"
                                                sx={{
                                                    borderRadius: 'xl',
                                                    p: 3,
                                                    // width: 200
                                                }}
                                            >
                                                <Typography level="title-lg" sx={{ mb: 2, textAlign: 'center' }}>Тарифы</Typography>
                                                <Box
                                                    sx={{
                                                        display: 'grid',
                                                        gridTemplateColumns: 'repeat(auto-fill, minmax(140px, 1fr))',
                                                        gap: 1.5,
                                                    }}
                                                >
                                                    { rates.map((item) => {
                                                        // const dayObj = selectRate ? days.find(obj => obj.id === selectRate) : undefined;
                                                        const selected = selectRates.includes(item?.id);
                                                        return (
                                                            <Card
                                                                color={selected ? "primary" : "neutral"}
                                                                variant="plain"
                                                                key={item?.name}
                                                                sx={{
                                                                    gap: 0,
                                                                    // boxShadow: 'none',
                                                                    transition: ".2s",
                                                                    '&:hover': { 
                                                                        bgcolor: 'primary.50',
                                                                        transform: 'scale(1.05)'
                                                                    },
                                                                }}
                                                            >
                                                                <CardContent>
                                                                    {item.icon}
                                                                    <Typography level="title-md">{item?.name}</Typography>
                                                                    <Typography level="body-sm">{item?.price} ₽</Typography>
                                                                </CardContent>
                                                                <Checkbox
                                                                    size="sm"
                                                                    color="neutral"
                                                                    overlay
                                                                    disableIcon
                                                                    variant="outlined"
                                                                    // checked={selected}
                                                                    onChange={(event) =>
                                                                        setSelectRates((prev) => {
                                                                            const set = new Set([...prev, item?.id]);
                                                                            if (!event.target.checked) {
                                                                                set.delete(item?.id);
                                                                            }
                                                                            return [...set];
                                                                        })
                                                                    }
                                                                    slotProps={{
                                                                        action: {
                                                                            sx: {
                                                                            ...(selected && {
                                                                                borderWidth: 2,
                                                                                borderColor:
                                                                                'var(--joy-palette-primary-outlinedBorder)',
                                                                            }),
                                                                            '&:hover': {
                                                                                bgcolor: 'transparent',
                                                                            },
                                                                            },
                                                                        },
                                                                    }}
                                                                />
                                                            </Card>
                                                        );
                                                    })}
                                                </Box>
                                            </Sheet> */}
                                            <Sheet
                                                variant="soft"
                                                sx={{
                                                    borderRadius: 'xl',
                                                    p: 3,
                                                    // width: 200
                                                }}
                                            >
                                                <Typography level="h3" sx={{ mb: 2, textAlign: 'center' }}>Тариф</Typography>
                                                <Grid
                                                    container 
                                                    spacing={2}
                                                    columns={{ xs: 2, sm: 4, smmd: 4, md: 4 }}
                                                    sx={{ 
                                                        alignItems: "stretch",
                                                        // flexGrow: 1, 
                                                        // width: '50%',
                                                        px: {
                                                            xs: 0,
                                                            sm: 2
                                                        }
                                                    }}
                                                >
                                                    {
                                                        rates.map((item) => {
                                                            return (
                                                                <Grid xs={2} sm={4} smmd={4} md={4} mdlg={2} key={item?.article} sx={{ display: "flex",
                                                                    flexDirection: "column" }}>
                                                                    <Card
                                                                        color="primary"
                                                                        variant="plain"
                                                                        key={item.name}
                                                                        sx={{
                                                                            gap: 0,
                                                                            height: "100%", // Равномерная высота
                                                                            // boxShadow: 'none',
                                                                            transition: ".2s",
                                                                            '&:hover': { 
                                                                                transform: 'scale(1.05)'
                                                                            },
                                                                        }}
                                                                    >
                                                                        <CardContent sx={{ justifyContent: "space-between" }}>
                                                                            <Box>
                                                                                <Typography level="h4" sx={{ color: item?.id == selectRate ? "primary.500" : "neutral.900" }}>{item?.name}</Typography>
                                                                                <Typography level="body-xs" sx={{ mt: 1 }}>{item?.description}</Typography>
                                                                                <Stack spacing={1} sx={{ mt: 1 }}>
                                                                                    { 
                                                                                        item?.rate_access?.map((item_rate) => {
                                                                                            return (
                                                                                                <Card
                                                                                                    // color="primary"
                                                                                                    variant="soft"
                                                                                                    // key={item_rate?.service}
                                                                                                    sx={{
                                                                                                        bgcolor: 'neutral.200',
                                                                                                        p: 1.5,
                                                                                                        boxShadow: 'none',
                                                                                                    }}
                                                                                                >
                                                                                                    <Typography level="body-xs" sx={{ color: 'neutral.700' }}>
                                                                                                        {item_rate?.name}
                                                                                                    </Typography>
                                                                                                </Card>
                                                                                            )
                                                                                        })
                                                                                    }
                                                                                </Stack>
                                                                            </Box>
                                                                            <Typography level="title-sm" sx={{ mt: 1 }}>Стоимость:{' '}
                                                                                <Typography level="title-md" sx={{ color: item?.id == selectRate ? "primary.500" : "neutral.900" }}>
                                                                                    {item?.price == 0 ? "Бесплатно" : `${item?.price} ₽`}
                                                                                </Typography>
                                                                            </Typography>
                                                                        </CardContent>
                                                                        <Radio
                                                                            size="sm"
                                                                            color="neutral" 
                                                                            overlay
                                                                            disableIcon
                                                                            variant="outlined"
                                                                            checked={item?.id == selectRate}
                                                                            onChange={() => setSelectRate(item?.id)}
                                                                            slotProps={{
                                                                            action: {
                                                                                sx: {
                                                                                ...(item.id == selectRate && {
                                                                                    borderWidth: 2,
                                                                                    borderColor:
                                                                                    'var(--joy-palette-primary-outlinedBorder)',
                                                                                }),
                                                                                '&:hover': {
                                                                                    bgcolor: 'transparent',
                                                                                },
                                                                                },
                                                                            },
                                                                            }}
                                                                        />
                                                                    </Card>
                                                                </Grid>
                                                            );
                                                        }
                                                    )}
                                                </Grid>
                                            </Sheet>
                                        </Stack>
                                        { selectRate &&
                                            (
                                                <Sheet
                                                    variant="outlined"
                                                    sx={{
                                                        height: "fit-content",
                                                        borderRadius: 'xl',
                                                        p: 3,
                                                        boxShadow: 'lg',
                                                        width: {
                                                            xs: 'auto',
                                                            md: '100%'
                                                        },
                                                        maxWidth: {
                                                            xs: "100%",
                                                            md: 280
                                                        }
                                                    }}
                                                >
                                                    <Stack spacing={2}>
                                                        <Stack spacing={2}>
                                                            <Typography level="h4" color="primary">
                                                                Ваш заказ
                                                            </Typography>
                                                            {totalCheck}
                                                            {/* {
                                                                selectRates.map((targetId) => {
                                                                    const obj = rates.find(obj => obj.id === targetId);
                                                                    // const dayObj = selectRate ? days.find(obj => obj.id === selectRate) : undefined;
                                                                    return (
                                                                        <Stack spacing={2} direction={"row"} sx={{ alignItems: "center", justifyContent: "space-between" }}>
                                                                            <Box>
                                                                                <Typography level="title-sm">
                                                                                    {obj?.name}
                                                                                </Typography>
                                                                                <Typography level="body-sm">
                                                                                    Доступ к функции "{obj?.name}"
                                                                                </Typography>
                                                                                <Typography level="body-sm" color="primary" sx={{ fontWeight: 'xl' }}>
                                                                                    {obj?.price} ₽
                                                                                </Typography>
                                                                            </Box>
                                                                            <IconButton size="sm"
                                                                                onClick={(event) =>
                                                                                    setSelectRates((prev) => {
                                                                                        const set = new Set([...prev, obj?.id]);
                                                                                        set.delete(obj?.id);
                                                                                        return [...set];
                                                                                    }
                                                                                )}
                                                                            >
                                                                                <ClearRoundedIcon/>
                                                                            </IconButton>
                                                                        </Stack>
                                                                    )
                                                                })
                                                            } */}
                                                            <Divider/>
                                                            <Stack spacing={2} direction={"row"} sx={{ justifyContent: "space-between" }}>
                                                                <Typography level="h4">
                                                                    Итого
                                                                </Typography>
                                                                <Typography level="h4">
                                                                    {
                                                                        rates?.find((obj) => obj.id == selectRate)?.price
                                                                    } ₽
                                                                </Typography>
                                                            </Stack>
                                                        </Stack>
                                                        { !account?.id ?
                                                            <>
                                                                <Divider/>
                                                                { error != "" &&
                                                                    <Typography sx={{ textAlign: 'center' }} color="danger" level="title-sm">
                                                                        {error}
                                                                    </Typography>
                                                                }
                                                                <Stack spacing={1.5}>
                                                                    <FormControl>
                                                                        <FormLabel>Почта*</FormLabel>
                                                                        {/* <InputMask mask="+7 (999) 999-99-99" value={phone} onChange={(e) => setPhone(e.target.value)}>
                                                                            {(inputProps) => <Input {...inputProps} type="tel" size="sm" required name="phone" disableUnderline placeholder="+7 (XXX) XXX-XX-XX" />}
                                                                        </InputMask> */}
                                                                        <Input value={email} onChange={(e) => setEmail(e.target.value)} size="sm" required type="email" placeholder="test@gmail.com" />
                                                                    </FormControl>
                                                                    <FormControl>
                                                                        <FormLabel>Пароль*</FormLabel>
                                                                        <Input value={pwd} onChange={(e) => setPwd(e.target.value)} size="sm" required type="password" placeholder="***" />
                                                                    </FormControl>
                                                                    <Button variant="solid" color="success" type="submit">
                                                                        Оплатить {
                                                                            rates?.find((obj) => obj.id == selectRate)?.price
                                                                        } ₽
                                                                    </Button>
                                                                </Stack>
                                                            </>
                                                            :
                                                            <Button variant="solid" color="success" type="submit">
                                                                Оплатить {
                                                                    rates?.find((obj) => obj.id == selectRate)?.price
                                                                } ₽
                                                            </Button>
                                                        }
                                                    </Stack>
                                                </Sheet>
                                            )
                                        }
                                    </Stack>
                                </form>
                            </ModalDialog>
                        </ModalOverflow>
                    </Modal>
                )
            }
        </AnimatePresence>
    );
}