import { Box, Button, FormControl, FormLabel, Input, Modal, ModalClose, ModalDialog, ModalOverflow, Stack, Typography } from "@mui/joy";
import { useContext, useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { LayoutContext } from "../../../context";
import { APICheckResendPwd } from "../../../api";
import { useParams } from "react-router-dom";


export default function ModalNewPassword() {
    const { modalNewPassword, setModalNewPassword } = useContext(LayoutContext);
    // const { token } = useParams(); // Извлекаем токен из URL

    const [ password, setPassword ] = useState();
    const [ password2, setPassword2 ] = useState();

    function reset() {
        if (password != password2) {
            alert("Пароли не совпадают");
            return
        }
        // Отправляем токен на бэкенд
        APICheckResendPwd(modalNewPassword?.token, password).then((data) => {
            if (data?.status == 'ok') {
                setModalNewPassword({ ...modalNewPassword, open: false });
                alert("Доступ восстановлен");
            }
            else if (data?.response?.data?.status == 'notfound') {
                alert(data?.response?.data?.message);
                setModalNewPassword({ ...modalNewPassword, open: false });
            }
            else if (data?.response?.data?.status == 'timeout') {
                alert(data?.response?.data?.message);
            }
        })
    }

    return (
        <AnimatePresence>
            {
                modalNewPassword?.open && (
                    <Modal
                        open={modalNewPassword?.open}
                        onClose={() => setModalNewPassword({ ...modalNewPassword, open: false })}
                        slotProps={{
                            backdrop: {
                                component: motion.div,
                                initial: { opacity: 0, backdropFilter: 'blur(0px)' },
                                animate: { opacity: 1, backdropFilter: 'blur(8px)' },
                                exit: { opacity: 0, backdropFilter: 'blur(0px)' },
                                transition: { duration: 0.3, ease: "easeInOut" },
                            }
                        }}
                    >
                        <ModalOverflow sx={{ height: 'auto' }}>
                            <ModalDialog
                                size="lg"
                                component={motion.div}
                                initial={{ opacity: 0, y: -10, scale: 0.98 }}
                                animate={{ opacity: 1, y: 0, scale: 1 }}
                                exit={{ opacity: 0, y: 10, scale: 0.98 }}
                                transition={{ duration: 0.2, ease: "easeInOut" }}
                                sx={{ alignItems: "center", maxWidth: 400, gap: 0 }}
                            >
                                <ModalClose />

                                <Box sx={{ mt: 2 }}>
                                    <img src="/lock.gif" width={120} style={{ "objectFit": 'cover' }}/>
                                </Box>

                                <Stack spacing={1}>
                                    <Typography sx={{ textAlign: 'center' }} level="h3">
                                        Письмо отправлено на почту
                                    </Typography>
                                    <Box>
                                        <Typography sx={{ textAlign: 'center' }} level="body-sm">
                                            Пожалуйста, введите новый пароль для восстановления доступа.
                                        </Typography>
                                        <Stack spacing={1} sx={{ mt: 2 }}>
                                            <FormControl sx={{ mt: 2 }}>
                                                <FormLabel>Новый пароль*</FormLabel>
                                                <Input size="sm" required type="password" value={password} onChange={(e) => setPassword(e.target.value)} placeholder="***" />
                                            </FormControl>
                                            <FormControl>
                                                <FormLabel>Повторить пароль*</FormLabel>
                                                <Input size="sm" required type="password" value={password2} onChange={(e) => setPassword2(e.target.value)} placeholder="***" />
                                            </FormControl>
                                        </Stack>
                                    </Box>
                                    <Box>
                                        <Button onClick={reset} sx={{ width: '100%', mt: 2 }} size="lg" variant="solid" color="primary" type="submit">
                                            Поменять
                                        </Button>
                                    </Box>
                                </Stack>
                            </ModalDialog>
                        </ModalOverflow>
                    </Modal>
                )
            }
        </AnimatePresence>
    );
}