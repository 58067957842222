import { AspectRatio, Box, Button, Container, Stack, Typography, typographyClasses } from "@mui/joy";
import { Link as RouterLink, useNavigate } from "react-router-dom";


import ArrowForwardRoundedIcon from '@mui/icons-material/ArrowForwardRounded';
import ExitToAppRoundedIcon from '@mui/icons-material/ExitToAppRounded';
import FlagRoundedIcon from '@mui/icons-material/FlagRounded';
import DashboardCustomizeRoundedIcon from '@mui/icons-material/DashboardCustomizeRounded';
import Header from "../../components/Header/Header";
import { useContext } from "react";
import { LayoutContext } from "../../context";
import { APIGetUser } from "../../api";



export default function BlockStart() {
    const navigate = useNavigate();
    const { setAccount, setModalReg, setModalRate, setModalAuth } = useContext(LayoutContext);

    const checkObj = (obj) => Object.keys(obj).length === 0

    function loginLK() {
        APIGetUser().then((data) => {
            if (data?.status) {
                setModalAuth(true);
            }
            if (data?.id) {
                setAccount(data);
                if (data?.rate_access?.length == 0) setModalRate(true);
                else navigate("/lk");
            }
        });
    }

    return (
        <Container
            sx={[
                (theme) => ({
                    // display: "flex",
                    // flexDirection: "row",
                    justifyContent: "center",

                    position: 'relative',
                    minHeight: '100vh',
                    alignItems: 'center',
                    py: 5,
                    gap: 4,
                    // [theme.breakpoints.up(834)]: {
                    //     flexDirection: 'row',
                    //     gap: 6,
                    // },
                    // [theme.breakpoints.up(1199)]: {
                    //     gap: 12,
                    // }
                }),
            ]}
        >
            <Header/>
            <Box
                sx={[
                    (theme) => ({
                        width: '100%',
                        position: 'relative',
                        flexDirection: 'column',
                        // minHeight: '100vh',
                        display: 'flex',
                        alignItems: 'center',
                        py: 10,
                        gap: 4,
                        [theme.breakpoints.up(834)]: {
                            flexDirection: 'row',
                            gap: 6,
                        },
                        [theme.breakpoints.up(1199)]: {
                            gap: 12,
                        }
                    }),
                ]}
            >
                <Box
                    sx={(theme) => ({
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        gap: '1rem',
                        maxWidth: '50ch',
                        textAlign: 'center',
                        flexShrink: 999,
                        [theme.breakpoints.up(834)]: {
                            minWidth: 420,
                            alignItems: 'flex-start',
                            textAlign: 'initial'
                        },
                        [`& .${typographyClasses.root}`]: {
                            textWrap: 'balance',
                        },
                    })}
                >
                    <Box>
                        <Typography color="primary" sx={{ fontSize: 'lg', fontWeight: 'lg' }}>
                            "Миллионы людей видели, как падают яблоки, но только Ньютон спросил почему."
                        </Typography>
                        <Typography color="neutral" level="body-sm">
                            - Бернард Барух
                        </Typography>
                    </Box>
                    <Typography
                        level="h1"
                        sx={{
                            lineHeight: 1,
                            fontWeight: 'xl',
                            fontSize: 'clamp(1.875rem, 1.3636rem + 2.1818vw, 3rem)',
                        }}
                    >
                        Сервис повышения эфективности маркетплейса
                    </Typography>
                    <Typography
                        textColor="text.secondary"
                        sx={{ fontSize: 'lg', lineHeight: 'lg' }}
                    >
                        Инструмент для анализа рекламы конкурентов и продвижения внешней рекламы
                    </Typography>

                    <Stack
                        direction={{ xs: "column", sm: "row" }}
                        sx={{
                            gap: 1,
                            flexWrap: 'wrap',
                            justifyContent: {
                                xs: "center",
                                md: "start"
                            },
                            alignItems: "center"
                        }}
                    >
                        <Button onClick={() => setModalReg(true)} size="lg" endDecorator={<FlagRoundedIcon fontSize="xl" />}>
                            Регистрация
                        </Button>
                        <Button onClick={() => setModalRate(true)} variant="soft" size="lg" endDecorator={<DashboardCustomizeRoundedIcon fontSize="xl" />}>
                            Тарифы
                        </Button>
                        {/* <RouterLink to="/lk/unit"> */}
                            <Button onClick={loginLK}  variant="soft" color="neutral" size="lg" endDecorator={<ExitToAppRoundedIcon fontSize="xl" />}>
                                Войти
                            </Button>
                        {/* </RouterLink> */}
                    </Stack>

                    <Typography>
                        {/* Already a member? <Link sx={{ fontWeight: 'lg' }}>Sign in</Link> */}
                    </Typography>
                    {/* <Typography
                        level="body-xs"
                        sx={{
                        position: 'absolute',
                        top: '2rem',
                        left: '50%',
                        transform: 'translateX(-50%)',
                        }}
                    >
                        HeroLeft01
                    </Typography> */}
                </Box>
                <AspectRatio
                    ratio={600 / 520}
                    variant="outlined"
                    maxHeight={300}
                    sx={(theme) => ({
                        minWidth: 300,
                        alignSelf: 'stretch',
                        [theme.breakpoints.up(834)]: {
                            alignSelf: 'initial',
                            flexGrow: 1,
                            '--AspectRatio-maxHeight': '520px',
                            '--AspectRatio-minHeight': '400px',
                        },
                        borderRadius: 'md',
                        bgcolor: 'background.level2',
                        flexBasis: '50%',
                    })}
                >
                    <img
                        src="/bg_wb_analitics.png"
                        alt=""
                    />
                </AspectRatio>
            </Box>
        </Container>
    );
}