import { BrowserRouter, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import './App.css';
import './fonts/fonts.css';

import { AnimatePresence, motion } from 'framer-motion';
import { CssVarsProvider, extendTheme } from '@mui/joy';
import LayoutProvider, { LayoutContext } from './context';
import { lazy, Suspense, useContext, useEffect } from 'react';

import LoaderPage from './components/LoaderPage/LoaderPage';
import ModalRegistration from './components/Modals/ModalRegistration';
import ModalRate from './components/Modals/ModalRate';
import PageLock from './pages/PageLock';
import ModalAuth from './components/Modals/ModalAuth';
import ModalInfo from './components/Modals/ModalInfo';
import ModalLogout from './components/Modals/ModalLogout';
import ModalUnit from './components/Modals/ModalUnit';
import { APIGetUser } from './api';
import ModalSendEmail from './components/Modals/ModalSendEmail';
import ModalSuccessCheckEmail from './components/Modals/ModalSuccessCheckEmail';
import ModalResendEmail from './components/Modals/ModalResendEmail';
import ModalResetPwdSendEmail from './components/Modals/ModalsResetPwd/ModalResetPwdSendEmail';
import ModalSendResetEmail from './components/Modals/ModalsResetPwd/ModalSendResetEmail';
import ActivatePage from './pages/ActivatePage';
import ModalNewPassword from './components/Modals/ModalsResetPwd/ModalNewPassword';
import ResetPage from './pages/ResetPage';
import ModalSuccessPay from './components/Modals/ModalSuccessPay';
import PayPage from './pages/PayPage';
import ModalSearchArticleItem from './components/Modals/ModalSearchArticleItem';


const StartPage = lazy(() => import('./pages/StartPage'));

const LKPage = lazy(() => import('./pages/LKPages/LKPage'));
const UnitPage = lazy(() => import('./pages/LKPages/UnitPage'));
const BloggersPage = lazy(() => import('./pages/LKPages/BloggersPage'));
const SearchArticPage = lazy(() => import('./pages/LKPages/SearchArticPage'));





const customTheme = extendTheme({
  radius: {
    xs: '8px',  // Мягкие закругления, 8px используется для всех мелких элементов
    sm: '12px', // Закругления для средних элементов
    md: '16px', // Более выраженное закругление для крупных элементов
    xl2: '50px'
  },
  fontFamily: {
    body: '"Inter", sans-serif',
    display: '"Inter", sans-serif',
    fallback: '"Inter", sans-serif',
  },
  breakpoints: {
    keys: [ "xs", "sm", "smmd", "md", "mdlg", "lg", "lgxl", "xl" ],
    values: {
      xs: 0,
      sm: 600,
      smmd: 800,
      md: 900,
      mdlg: 1100,
      lg: 1200,
      lgxl: 1400,
      xl: 1536,
    }
  },
  // fontSize: {
  //   ultrah1: {
  //     fontSize: 'clamp(20px, 6vw, 100px)'
  //   },
  // },
  typography: {
    ultrah1: {
      fontSize: 'clamp(20px, 10vw, 100px)',
      fontWeight: 700
    },
    h1: {
      fontSize: 'clamp(1.875rem, 1.3636rem + 2.1818vw, 3.5rem)'
    },
    h2: {
      fontSize: 'clamp(30px, 4vw, 35px)'
    }
  },
  colorSchemes: {
    light: {
      palette: {
        primary: {
          50: '#FFE5E7',    // Очень светлый розовый
          100: '#FFBCC0',   // Светло-розовый
          200: '#FF9298',   // Светло-красный
          300: '#FF666F',   // Красный с розовым оттенком
          400: '#FF3D47',   // Чуть более темный оттенок
          500: '#FF050E',   // Основной цвет бренда
          600: '#b70309',   // Чуть темнее основного цвета
          700: '#BF040B',   // Темно-красный
          800: '#9B0309',   // Более темный оттенок
          900: '#770206',   // Очень темный оттенок
          1000: '#E2050D6e' // Полупрозрачный вариант для наложений
        },
        neutral: {
          50: '#F9FAFC',     // Очень светлый оттенок
          100: '#F4F5F8',    // Светлый оттенок
          200: '#EDEFF5',    // Светло-серый с голубоватым оттенком
          300: '#E3E6EE',    // Главный цвет (основной оттенок)
          400: '#C5C9D3',    // Немного темнее
          500: '#A7ACB8',    // Средний серый с синим оттенком
          600: '#898E9D',    // Темнее среднего
          700: '#303030',
          800: '#212121',
          900: '#181818',
        },
        // neutral: {
        //   50: '#F9FAFC',     // Очень светлый оттенок
        //   100: '#F4F5F8',    // Светлый оттенок
        //   200: '#EDEFF5',    // Светло-серый с голубоватым оттенком
        //   300: '#E3E6EE',    // Главный цвет (основной оттенок)
        //   400: '#C5C9D3',    // Немного темнее
        //   500: '#A7ACB8',    // Средний серый с синим оттенком
        //   600: '#898E9D',    // Темнее среднего
        //   700: '#6B7183',    // Темно-серый с голубым оттенком
        //   800: '#4C5169',    // Еще более темный
        //   900: '#2E334F',    // Очень темный оттенок
        //   1000: '#E3E6EE6e'  // Полупрозрачный основной цвет для наложений
        // },
        success: {
          50: '#E8F8E5',     // Очень светлый зеленый
          100: '#C2EDB8',    // Светло-зеленый
          200: '#9BE28A',    // Нежный зеленый
          300: '#74D65D',    // Светлый травяной зеленый
          400: '#55C63D',    // Чуть темнее зеленый
          500: '#35B50E',    // Основной зеленый цвет
          600: '#2E9E0C',    // Немного темнее основного
          700: '#25830A',    // Темно-зеленый
          800: '#1C6708',    // Глубокий зеленый
          900: '#134A05',    // Очень темный зеленый
          1000: '#35B50E6e'  // Полупрозрачный основной цвет для наложений
        },
        warning: {
          50: '#FFF1E0',    // Очень светлый оранжевый
          100: '#FFD9B3',   // Светлый персиковый
          200: '#FFC080',   // Нежный оранжевый
          300: '#FFA74D',   // Светлый оранжевый
          400: '#FF961F',   // Чуть темнее основного
          500: '#FF8A05',   // Основной цвет (яркий оранжевый)
          600: '#E67B04',   // Темный оранжевый
          700: '#CC6C03',   // Глубокий оранжевый
          800: '#B35D02',   // Темный оранжево-коричневый
          900: '#8A4702',   // Очень темный оттенок
          1000: '#FF8A056e' // Полупрозрачный основной цвет для наложений
        },
        star: {
          50: '#f5f4ae',
          100: '#dedc37',
        }
      },
      typography: {
        fontFamily: '"SweraDemo", sans-serif',
      },
    }
  },
  components: {
    JoyInput: {
      styleOverrides: {
        root: ({ theme }) => ({
          '--Input-focusedInset': 'var(--any, )',
          '--Input-focusedHighlight': theme.colorSchemes.light.palette.primary[600],
          '&::before': {
            transition: 'box-shadow .15s ease-in-out',
          },
          '&:focus-within': {
            borderColor: theme.colorSchemes.light.palette.primary[600],
          },
          borderRadius: theme.radius.xs,
          // fontWeight: theme.fontWeight.xs, // Используем толщину 500 для кнопок
        }),
      },
    },
    JoyButton: {
      styleOverrides: {
        root: ({ theme }) => ({
          borderRadius: theme.radius.xs,
          fontWeight: theme.fontWeight.xs, // Используем толщину 500 для кнопок
        }),
      },
    },
    JoyIconButton: {
      styleOverrides: {
        root: ({ theme }) => ({
          borderRadius: theme.radius.xs,
          fontWeight: theme.fontWeight.xs, // Используем толщину 500 для кнопок
        }),
      },
    },
    JoyChip: {
      styleOverrides: {
        root: ({ theme }) => ({
          borderRadius: theme.radius.xs
        }),
      },
    },
    JoyCard: {
      styleOverrides: {
        root: {
          borderRadius: 'var(--joy-radius-md)',  // Более выраженное закругление для карточек
          boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
        },
      },
    },
  },
});


// -=-=- Анимация Страницы -=-=-
function PageWrapper({ children }) {
  return (
    <motion.div
      initial={{ opacity: 0, y: -40, scale: 0.98 }}
      animate={{ opacity: 1, y: 0, scale: 1 }}
      exit={{ opacity: 0, y: 40, scale: 0.98 }}
      transition={{ duration: 0.2 }}
    >
      {children}
    </motion.div>
  )
};


// Проверить нахождение в личном кабинете
export function isInLk(location) {
  const lkPattern = /^\/lk(\/|$)/; // Проверяет, начинается ли путь с "/lk/"
  return lkPattern.test(location.pathname);
};


function AnimatePage() {
  const location = useLocation();
  const navigate = useNavigate();
  const { lock, setLock } = useContext(LayoutContext);

  useEffect(() => {
    APIGetUser().then((data) => {
        if (data?.status == 403) {
          if (isInLk(location)) navigate('/');
        }
        else {
          if (data?.rate_access?.length == 0) navigate('/');
        }
    })
  }, []);

  return (
    <AnimatePresence mode='wait'>
      <Routes location={location} key={location.pathname}>
        <Route path='/' element={
          <PageWrapper>
            <StartPage/>
          </PageWrapper>
        }/>

        <Route path='/lk' element={<LKPage/>}>
          <Route path="unit" element={<UnitPage />} />
          <Route path="search-artic" element={<SearchArticPage />} />
          <Route path="bloggers" element={<BloggersPage />} />
        </Route>
        
        <Route path='/activate/:token' element={<ActivatePage/>}/>
        <Route path='/reset/:token' element={<ResetPage/>}/>
        <Route path='/pay/:uuid' element={<PayPage/>}/>

        
      </Routes>
      
      <ModalUnit/>
      <ModalRegistration/>
      <ModalAuth/>
      <ModalSendEmail/>
      <ModalSuccessCheckEmail/>
      <ModalResendEmail/>
      <ModalSearchArticleItem/>
      <ModalResetPwdSendEmail/>
      <ModalNewPassword/>
      <ModalSendResetEmail/>
      <ModalLogout/>
      <ModalRate/>
      <ModalSuccessPay/>
      <ModalInfo/>
      { lock && (
        <PageLock/>
      )}
    </AnimatePresence>
  );
}



function App() {
  return (
    <CssVarsProvider theme={customTheme}>
      <LayoutProvider>
        <Suspense fallback={<LoaderPage/>}>
          <BrowserRouter>
            <AnimatePage/>
          </BrowserRouter>
        </Suspense>
      </LayoutProvider>
    </CssVarsProvider>
  );
}




export default App;
