import { Box } from "@mui/joy";

import { Link as RouterLink, useNavigate, useParams } from "react-router-dom";
import { useContext, useEffect } from "react";
import { APICheckEmail, APICheckResendPwd } from "../api";
import { LayoutContext } from "../context";




export default function ResetPage() {
    const navigate = useNavigate();
    const { token } = useParams(); // Извлекаем токен из URL
    const { setModalNewPassword } = useContext(LayoutContext);


    useEffect(() => {
        // Отправляем токен на бэкенд
        navigate("/");
        setModalNewPassword({ token: token, open: true });
    }, []);

    return (
        <>
            <Box>
            </Box>
        </>
    );
}