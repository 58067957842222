import { Button, Chip, DialogContent, DialogTitle, FormControl, FormLabel, Grid, Input, Modal, ModalClose, ModalDialog, ModalOverflow, Option, Select, Sheet, Stack, Typography } from "@mui/joy";
import { useContext, useEffect, useState } from "react";
import { LayoutContext } from "../../context";
import { motion, AnimatePresence } from "framer-motion";
import InputMask from 'react-input-mask';
import { useLocation, useNavigate } from "react-router-dom";
import { APICreateUnit } from "../../api";
import ToolTipTable from "../TableUnitEconomic/ToolTipTable";


export default function ModalUnit() {
    const { modalUnit, setModalUnit, unit_warehouse } = useContext(LayoutContext);

    // Выбор скалада
    const [ selectedWarehouse, setSelectedWarehouse ] = useState("");
    const { formatPrice, account, setAccount } = useContext(LayoutContext);

    // Обработчик изменения выбора склада
    const handleChangeWarehouse = (event, newValue) => {
        setSelectedWarehouse(newValue);
        console.log(unit_warehouse?.find((element) => element.id == newValue));
    };


    function createUnit(e) {
        e.preventDefault();

        const _data = {
            warehouse_id: selectedWarehouse,
            name: name,
            length: length,
            width: width,
            height: height,
            purchase_cost: purchase_cost,
            cost_of_expenses: cost_of_expenses,
            quantity: quantity,
            lot_amount_without_delivery: lot_amount_without_delivery,
            batch_amount: batch_amount,
            fulfilment: fulfilment,
            procurement_packaging: procurement_packaging,
            lot_amount_packaging: lot_amount_packaging,
            max_price_before_discount: max_price_before_discount,
            discount: discount,
            seller_discount_price: seller_discount_price,
            commission_WB: commission_WB,
            redemption_percentage: redemption_percentage,
            packaging_volume: packaging_volume,
            basic_cost_of_logistics: basic_cost_of_logistics,
            cost_of_logistics_including_redemption: cost_of_logistics_including_redemption,
            basic_return_value: basic_return_value,
            return_value_including_redemption: return_value_including_redemption,
            total_shipping: total_shipping,
            tax: tax,
            tax_per_unit_of_goods: tax_per_unit_of_goods,
            revenue_per_unit_of_goods: revenue_per_unit_of_goods,
            revenue_per_batch: revenue_per_batch,
            advertising_plan: advertising_plan,
            orders_per_day_plan: orders_per_day_plan,
            sales_per_day: sales_per_day,
            storage: storage,
            storage_cost: storage_cost
        };
        
        APICreateUnit(_data).then((data) => {
            if (data) {
                console.log("Create", data);
                setAccount({ ...account, calculations_unit_user: data })
                
                setModalUnit(false);
                
                setSelectedWarehouse("");
                setName("");
                setLength("");
                setWidth("");
                setHeight("");
                setPurchase_cost("");
                setCost_of_expenses("");
                setQuantity("");
                setLot_amount_without_delivery("-");
                setBatch_amount("-");
                setFulfilment("");
                setProcurement_packaging("-");
                setLot_amount_packaging("-");
                setMax_price_before_discount("");
                setDiscount("-");
                setSeller_discount_price("");
                setCommission_WB("-");
                setRedemption_percentage("");
                setPackaging_volume("-");
                setBasic_cost_of_logistics("-");
                setCost_of_logistics_including_redemption("-");
                setBasic_return_value("");
                setReturn_value_including_redemption("-");
                setTotal_shipping("-");
                setTax("");
                setTax_per_unit_of_goods("-");
                setRevenue_per_unit_of_goods("-");
                setRevenue_per_batch("-");
                setAdvertising_plan("");
                setOrders_per_day_plan("");
                setSales_per_day("-");
                setStorage("-");
                setStorage_cost("-");
            }
        })
    }


    // Название
    const [ name, setName ] = useState();
    // Длина упаковки
    const [ length, setLength ] = useState();
    // Ширина упаковки
    const [ width, setWidth ] = useState();
    // Высота упаковки
    const [ height, setHeight ] = useState();

    // Стоимость закупки
    const [ purchase_cost, setPurchase_cost ] = useState();
    // Стоимость расходов (доставка)
    const [ cost_of_expenses, setCost_of_expenses ] = useState();
    // Количество
    const [ quantity, setQuantity ] = useState();
    // Фулфилмент
    const [ fulfilment, setFulfilment ] = useState();
    // Цена до скидки
    const [ max_price_before_discount, setMax_price_before_discount ] = useState();
    // Скидка продавца
    // const [ discount, setDiscount ] = useState();
    // Цена со скидкой продавца
    const [ seller_discount_price, setSeller_discount_price ] = useState();
    // Процент выкупа
    const [ redemption_percentage, setRedemption_percentage ] = useState();
    // Базовая стоимость возврата
    const [ basic_return_value, setBasic_return_value ] = useState();
    // Налог
    const [ tax, setTax ] = useState();
    // Реклама план
    const [ advertising_plan, setAdvertising_plan ] = useState();
    // Заказов в день план
    const [ orders_per_day_plan, setOrders_per_day_plan ] = useState();

    
    
    // Сумма партии без доставки
    const [ lot_amount_without_delivery, setLot_amount_without_delivery ] = useState('-');
    // Сумма партии 
    const [ batch_amount, setBatch_amount ] = useState('-');
    // Закупка + Упаковка
    const [ procurement_packaging, setProcurement_packaging ] = useState('-');
    // Сумма партии + Упаковка
    const [ lot_amount_packaging, setLot_amount_packaging ] = useState('-');
    // Цена со скидкой продавца
    // const [ seller_discount_price, setSeller_discount_price ] = useState('-');
    // Скидка продавца
    const [ discount, setDiscount ] = useState('-');
    // Объем упаковки, литр
    const [ packaging_volume, setPackaging_volume ] = useState('-');
    // Комиссия Вб
    const [ commission_WB, setCommission_WB ] = useState('-');
    // Базовая стоимость логистики
    const [ basic_cost_of_logistics, setBasic_cost_of_logistics ] = useState('-');
    // Стоимость логистики с учетом выкупа
    const [ cost_of_logistics_including_redemption, setCost_of_logistics_including_redemption ] = useState('-');
    // Стоимость возврата с учетом выкупа
    const [ return_value_including_redemption, setReturn_value_including_redemption ] = useState('-');
    // Итого Доставка
    const [ total_shipping, setTotal_shipping ] = useState('-');
    // Налог с единицы товара
    const [ tax_per_unit_of_goods, setTax_per_unit_of_goods ] = useState('-');
    // Выручка за единицы товара
    const [ revenue_per_unit_of_goods, setRevenue_per_unit_of_goods ] = useState('-');
    // Выручка на партию
    const [ revenue_per_batch, setRevenue_per_batch ] = useState('-');
    // Продаж в день
    const [ sales_per_day, setSales_per_day ] = useState('-');
    // Хранение, дней
    const [ storage, setStorage ] = useState('-');
    // Стоимость хранения
    const [ storage_cost, setStorage_cost ] = useState('-');
    
    // // Прибыль за минусом расходов, рекламы,хранения, налогов
    // const [ profit_minus_expenses_advertising, setProfit_minus_expenses_advertising ] = useState('-');
    // // Рентабельность капитала
    // const [ return_on_equity, setReturn_on_equity ] = useState('-');
    // // Перечисление продавцу за единицу
    // const [ transfer_to_seller_per_unit, setTransfer_to_seller_per_unit ] = useState('-');


    useEffect(() => {
        if (purchase_cost && quantity) {
            setLot_amount_without_delivery(purchase_cost * quantity);
        }
    }, [purchase_cost, quantity]);

    useEffect(() => {
        if (cost_of_expenses && purchase_cost && quantity) {
            setBatch_amount(cost_of_expenses * quantity + purchase_cost * quantity);
        }
    }, [cost_of_expenses, purchase_cost, quantity]);

    useEffect(() => {
        if (purchase_cost && cost_of_expenses && fulfilment) {
            console.log(purchase_cost);
            setProcurement_packaging((parseFloat(purchase_cost) + parseFloat(cost_of_expenses) + parseFloat(fulfilment)).toFixed(2));
        }
    }, [purchase_cost, cost_of_expenses, fulfilment]);

    useEffect(() => {
        if (quantity && procurement_packaging) {
            setLot_amount_packaging(quantity * procurement_packaging);
        }
    }, [quantity, procurement_packaging]);

    useEffect(() => {
        if (max_price_before_discount && seller_discount_price) {
            setDiscount((((max_price_before_discount - seller_discount_price) / max_price_before_discount) * 100).toFixed(2));
        }
    }, [max_price_before_discount, seller_discount_price]);

    // Базовая стоимость логистики
    useEffect(() => {
        if (selectedWarehouse && packaging_volume) {
            const warehouse = unit_warehouse?.find((element) => element.id == selectedWarehouse);
            setBasic_cost_of_logistics(
                (packaging_volume > 1 ? parseFloat(warehouse?.logistics_for_1l) + parseFloat(warehouse?.add_l) * (parseFloat(packaging_volume) - 1) : parseFloat(warehouse?.logistics_for_1l) * packaging_volume).toFixed(2)
            );
        }
    }, [selectedWarehouse, packaging_volume]);

    useEffect(() => {
        if (seller_discount_price) {
            setCommission_WB(Math.round(seller_discount_price * 0.25));
        }
    }, [seller_discount_price]);

    useEffect(() => {
        if (length && width && height) {
            setPackaging_volume((length * width * height / 1000).toFixed(2));
        }
    }, [length, width, height]);

    // Стоимость логистики с учетом выкупа
    useEffect(() => {
        if (redemption_percentage && basic_cost_of_logistics) {
            setCost_of_logistics_including_redemption(((100/redemption_percentage)*basic_cost_of_logistics).toFixed(2));
        }
    }, [redemption_percentage, basic_cost_of_logistics]);

    useEffect(() => {
        if (redemption_percentage && basic_return_value) {
            setReturn_value_including_redemption(((100/redemption_percentage-1)*basic_return_value).toFixed(2));
        }
    }, [redemption_percentage, basic_return_value]);
    
    // Итого Доставка
    useEffect(() => {
        if (cost_of_logistics_including_redemption && return_value_including_redemption) {
            setTotal_shipping((parseFloat(cost_of_logistics_including_redemption) + parseFloat(return_value_including_redemption)).toFixed(2));
        }
    }, [cost_of_logistics_including_redemption, return_value_including_redemption]);

    // Налог с единицы товара
    useEffect(() => {
        if (seller_discount_price && tax) {
            setTax_per_unit_of_goods((parseFloat(seller_discount_price) * parseFloat(tax / 100)).toFixed(2));
        }
    }, [seller_discount_price, tax]);

    // Выручка за единицы товара
    useEffect(() => {
        if (seller_discount_price && commission_WB && total_shipping) {
            setRevenue_per_unit_of_goods((seller_discount_price - commission_WB - total_shipping).toFixed(2));
        }
    }, [seller_discount_price, commission_WB, total_shipping]);

    // Выручка на партию
    useEffect(() => {
        if (revenue_per_unit_of_goods && quantity) {
            setRevenue_per_batch((parseFloat(revenue_per_unit_of_goods) * parseFloat(quantity)).toFixed(2));
        }
    }, [revenue_per_unit_of_goods, quantity]);

    // Продаж в день
    useEffect(() => {
        if (orders_per_day_plan && redemption_percentage) {
            setSales_per_day(Math.round(orders_per_day_plan * redemption_percentage/100));
        }
    }, [orders_per_day_plan, redemption_percentage]);

    // Хранение, дней
    useEffect(() => {
        if (quantity && sales_per_day) {
            setStorage(Math.round(quantity / sales_per_day));
        }
    }, [quantity, sales_per_day]);


    // Стоимость хранения
    useEffect(() => {
        if (quantity && sales_per_day && storage && packaging_volume) {
            const warehouse = unit_warehouse?.find((element) => element.id == selectedWarehouse);
            setStorage_cost(
                (
                    ((parseFloat(quantity) + parseFloat(sales_per_day) + (parseFloat(quantity) - parseFloat(storage) * parseFloat(sales_per_day))) / 2 *
                        parseFloat(storage) + (parseFloat(quantity) - parseFloat(storage) * parseFloat(sales_per_day))) *
                        parseFloat(packaging_volume) > 1 ? parseFloat(warehouse?.storage_for_1l) + (parseFloat(warehouse?.add_l2) * (parseFloat(packaging_volume) - 1)) : parseFloat(warehouse?.storage_for_1l) * parseFloat(packaging_volume)
                ).toFixed(2)
            );
        }
    }, [selectedWarehouse, quantity, sales_per_day, storage, packaging_volume]);


    // // Прибыль за минусом расходов, рекламы,хранения, налогов
    // useEffect(() => {
    //     if (true) {
    //         setProfit_minus_expenses_advertising();
    //     }
    // }, []);

    // // Рентабельность капитала
    // useEffect(() => {
    //     if (true) {
    //         setReturn_on_equity();
    //     }
    // }, []);

    // // Перечисление продавцу за единицу
    // useEffect(() => {
    //     if (true) {
    //         setTransfer_to_seller_per_unit();
    //     }
    // }, []);



    return (
        <AnimatePresence>
            {
                modalUnit && (
                    <Modal
                        open={modalUnit}
                        onClose={() => setModalUnit(false)}
                        slotProps={{
                            backdrop: {
                                component: motion.div,
                                initial: { opacity: 0, backdropFilter: 'blur(0px)' },
                                animate: { opacity: 1, backdropFilter: 'blur(8px)' },
                                exit: { opacity: 0, backdropFilter: 'blur(0px)' },
                                transition: { duration: 0.3, ease: "easeInOut" },
                            }
                        }}
                    >
                        <ModalOverflow sx={{ height: 'auto' }}>
                            <ModalDialog
                                size="md"
                                component={motion.div}
                                initial={{ opacity: 0, y: -10, scale: 0.98 }}
                                animate={{ opacity: 1, y: 0, scale: 1 }}
                                exit={{ opacity: 0, y: 10, scale: 0.98 }}
                                transition={{ duration: 0.2, ease: "easeInOut" }}
                            >
                                <ModalClose />
                                <Typography sx={{ textAlign: 'center', mb: 3 }} level="h3">
                                    Unit-калькулятор
                                </Typography>
                                
                                <form
                                    onSubmit={createUnit}
                                >
                                    <Grid container
                                        spacing={3}
                                        columns={{ xs: 4, sm: 8, smmd: 8, md: 16, mdlg: 6, lg: 16, lgxl: 16, xl: 16 }}
                                        sx={{ justifyContent: "center" }}
                                    >
                                        <Grid xs={4} sm={8} smmd={8} md={8} mdlg={3} lg={8} lgxl={8} xl={8}>
                                            <Sheet variant="soft" sx={{ px: 2, py: 3, borderRadius: 'xl' }}>
                                                <Stack direction={"column"} spacing={{ xs: 0.5, sm: 1 }}>
                                                    <Stack direction={{ xs: "column", sm: "row" }} spacing={0.2} sx={{ alignItems: { xs: "flex-start", sm: "center" } }}>
                                                        <Typography level="title-sm" sx={{ width: "100%" }}>Название товара*</Typography>
                                                        <Input required type="text" placeholder="Босоножки" sx={{ width: "100%" }} value={name} onChange={(e) => setName(e.target.value)} />
                                                    </Stack>
                                                    <Stack direction={{ xs: "column", sm: "row" }} spacing={0.2} sx={{ alignItems: { xs: "flex-start", sm: "center" } }}>
                                                        <Typography level="title-sm" sx={{ width: "100%" }}><ToolTipTable title={"Длина упаковки, см"} description={"Это размеры упаковки единицы вашего товара. Исходя из размеров упаковки будет расчитан объем и затраты на логистику и хранение"} /></Typography>
                                                        <Input required type="number" placeholder="23" sx={{ width: "100%" }} value={length} onChange={(e) => setLength(e.target.value)} />
                                                    </Stack>
                                                    <Stack direction={{ xs: "column", sm: "row" }} spacing={0.2} sx={{ alignItems: { xs: "flex-start", sm: "center" } }}>
                                                        <Typography level="title-sm" sx={{ width: "100%" }}><ToolTipTable title={"Ширина упаковки, см"} description={"Это размеры упаковки единицы вашего товара. Исходя из размеров упаковки будет расчитан объем и затраты на логистику и хранение"} /></Typography>
                                                        <Input required type="number" placeholder="32" sx={{ width: "100%" }} value={width} onChange={(e) => setWidth(e.target.value)} />
                                                    </Stack>

                                                    <Stack direction={{ xs: "column", sm: "row" }} spacing={0.2} sx={{ alignItems: { xs: "flex-start", sm: "center" } }}>
                                                        <Typography level="title-sm" sx={{ width: "100%" }}><ToolTipTable title={"Высота упаковки, см"} description={"Это размеры упаковки единицы вашего товара. Исходя из размеров упаковки будет расчитан объем и затраты на логистику и хранение"} /></Typography>
                                                        <Input required type="number" placeholder="30" sx={{ width: "100%" }} value={height} onChange={(e) => setHeight(e.target.value)} />
                                                    </Stack>
                                                    <Stack direction={{ xs: "column", sm: "row" }} spacing={0.2} sx={{ alignItems: { xs: "flex-start", sm: "center" } }}>
                                                        <Typography level="title-sm" sx={{ width: "100%" }}><ToolTipTable title={"Склад WB"} description={"Выберите склад WB из списка. Стоимость логистики и хранения будут расчитываться исходя из тарифов выбранного склада"} /></Typography>
                                                        <Select required placeholder="Склад WB" sx={{ width: "100%", borderRadius: 'xs' }} value={selectedWarehouse} onChange={handleChangeWarehouse}>
                                                            { unit_warehouse?.map((item) => (
                                                                <Option value={item?.id}>{item?.warehouse}</Option>
                                                                ))
                                                            }
                                                        </Select>
                                                    </Stack>
                                                    <Stack direction={{ xs: "column", sm: "row" }} spacing={0.2} sx={{ alignItems: { xs: "flex-start", sm: "center" } }}>
                                                        <Typography level="title-sm" sx={{ width: "100%" }}><ToolTipTable title={"Стоимость закупки (₽)"} description={"Это входящая цена по которой вы приобрели товар (себестоимость)"} /></Typography>
                                                        <Input required type="number" placeholder="650" sx={{ width: "100%" }} value={purchase_cost} onChange={(e) => setPurchase_cost(e.target.value)} />
                                                    </Stack>

                                                    <Stack direction={{ xs: "column", sm: "row" }} spacing={0.2} sx={{ alignItems: { xs: "flex-start", sm: "center" } }}>
                                                        <Typography level="title-sm" sx={{ width: "100%" }}>Стоимость расходов (доставка) (₽)*</Typography>
                                                        <Input required type="number" placeholder="100" sx={{ width: "100%" }} value={cost_of_expenses} onChange={(e) => setCost_of_expenses(e.target.value)} />
                                                    </Stack>
                                                    <Stack direction={{ xs: "column", sm: "row" }} spacing={0.2} sx={{ alignItems: { xs: "flex-start", sm: "center" } }}>
                                                        <Typography level="title-sm" sx={{ width: "100%" }}><ToolTipTable title={"Количество"} description={"Общее количество товара"} /></Typography>
                                                        <Input required type="number" placeholder="2400" sx={{ width: "100%" }} value={quantity} onChange={(e) => setQuantity(e.target.value)} />
                                                    </Stack>
                                                    <Stack direction={{ xs: "column", sm: "row" }} spacing={0.2} sx={{ alignItems: { xs: "flex-start", sm: "center" } }}>
                                                        <Typography level="title-sm" sx={{ width: "100%" }}><ToolTipTable title={"Фулфилмент"} description={"Затраты на услуги фулфилмента за единицу товара (проверка на брак, упаковка, маркировка и т.п.)"} /></Typography>
                                                        <Input required type="number" placeholder="35" sx={{ width: "100%" }} value={fulfilment} onChange={(e) => setFulfilment(e.target.value)} />
                                                    </Stack>

                                                    <Stack direction={{ xs: "column", sm: "row" }} spacing={0.2} sx={{ alignItems: { xs: "flex-start", sm: "center" } }}>
                                                        <Typography level="title-sm" sx={{ width: "100%" }}><ToolTipTable title={"Цена до скидки (₽)"} description={"Здесь указывается завышенная цена на товар от которой будет установлена скидка, чтобы покупатель видел выгоду (на сайте и в приложении WB эта цена будет зачеркнута и указана цена со скидкой)"} /></Typography>
                                                        <Input required type="number" placeholder="5310" sx={{ width: "100%" }} value={max_price_before_discount} onChange={(e) => setMax_price_before_discount(e.target.value)} />
                                                    </Stack>
                                                    <Stack direction={{ xs: "column", sm: "row" }} spacing={0.2} sx={{ alignItems: { xs: "flex-start", sm: "center" } }}>
                                                        <Typography level="title-sm" sx={{ width: "100%" }}><ToolTipTable title={"Цена со скидкой продавца (без СПП)"} description={"Эта скидка определяет реальную (конечную) цену продажи, от которой будет считаться ваша прибыль и рентабельность"} /></Typography>
                                                        <Input required type="number" placeholder="5000" sx={{ width: "100%" }} value={seller_discount_price} onChange={(e) => setSeller_discount_price(e.target.value)} />
                                                    </Stack>
                                                    <Stack direction={{ xs: "column", sm: "row" }} spacing={0.2} sx={{ alignItems: { xs: "flex-start", sm: "center" } }}>
                                                        <Typography level="title-sm" sx={{ width: "100%" }}>Комиссия Вб*</Typography>
                                                        <Input required type="number" placeholder="55" sx={{ width: "100%" }} value={commission_WB} onChange={(e) => setCommission_WB(e.target.value)} />
                                                    </Stack>

                                                    <Stack direction={{ xs: "column", sm: "row" }} spacing={0.2} sx={{ alignItems: { xs: "flex-start", sm: "center" } }}>
                                                        <Typography level="title-sm" sx={{ width: "100%" }}><ToolTipTable title={"Процент выкупа (%)"} description={"Процент выкупа — это соотношение заказанных товаров к выкупленным. Для разных категорий товаров этот показатель может быть разным. Например, для одежды и обуви нормальным считается процент выкупа в диапазоне 40–60 %. В других нишах  80–90 %. Процент выкупа зависит от точности описания товара и количества брака"} /></Typography>
                                                        <Input required type="number" placeholder="63" sx={{ width: "100%" }} value={redemption_percentage} onChange={(e) => setRedemption_percentage(e.target.value)} />
                                                    </Stack>
                                                    <Stack direction={{ xs: "column", sm: "row" }} spacing={0.2} sx={{ alignItems: { xs: "flex-start", sm: "center" } }}>
                                                        <Typography level="title-sm" sx={{ width: "100%" }}>Базовая стоимость возврата*</Typography>
                                                        <Input required type="number" placeholder="50" sx={{ width: "100%" }} value={basic_return_value} onChange={(e) => setBasic_return_value(e.target.value)} />
                                                    </Stack>
                                                    <Stack direction={{ xs: "column", sm: "row" }} spacing={0.2} sx={{ alignItems: { xs: "flex-start", sm: "center" } }}>
                                                        <Typography level="title-sm" sx={{ width: "100%" }}><ToolTipTable title={"Налог (%)"} description={'Расчетный процент налога. Например, для системы налогообложения "УСН Доходы" составляет 7% (6% налог плюс 1% взносы в соц.страх)'} /></Typography>
                                                        <Input required type="number" placeholder="7" sx={{ width: "100%" }} value={tax} onChange={(e) => setTax(e.target.value)} />
                                                    </Stack>

                                                    <Stack direction={{ xs: "column", sm: "row" }} spacing={0.2} sx={{ alignItems: { xs: "flex-start", sm: "center" } }}>
                                                        <Typography level="title-sm" sx={{ width: "100%" }}><ToolTipTable title={"Реклама план"} description={'Планируемые затраты на рекламу. Как правило эти затраты зависят от категории товара, сезонности и т.п.'} /></Typography>
                                                        <Input required type="number" placeholder="40000" sx={{ width: "100%" }} value={advertising_plan} onChange={(e) => setAdvertising_plan(e.target.value)} />
                                                    </Stack>
                                                    <Stack direction={{ xs: "column", sm: "row" }} spacing={0.2} sx={{ alignItems: { xs: "flex-start", sm: "center" } }}>
                                                        <Typography level="title-sm" sx={{ width: "100%" }}><ToolTipTable title={"Заказов в день план"} description={'Планируемое количество заказов в день. Этот показатель зависит от рекламы, цены, конкуренции и. т.п. Проводя эффективную рекламную кампанию и временно увеличивая скидку на товар вы можете кратно увеличить количество заказов в день. Этот показатель прямо влияет на оборачиваемость товара и затраты на хранение.'} /></Typography>
                                                        <Input required type="number" placeholder="45" sx={{ width: "100%" }} value={orders_per_day_plan} onChange={(e) => setOrders_per_day_plan(e.target.value)} />
                                                    </Stack>
                                                </Stack>
                                            </Sheet>
                                        </Grid>

                                        <Grid xs={4} sm={8} smmd={8} md={8} mdlg={3} lg={8} lgxl={8} xl={8}>
                                            <Sheet variant="soft" sx={{ px: 2, py: 3, borderRadius: 'xl' }}>
                                                <Stack direction={"column"} spacing={1}>
                                                    <Stack direction={"row"}>
                                                        <Typography level="body-md" sx={{ width: "100%" }}>Сумма партии без доставки (₽)</Typography>
                                                        <Typography level="title-md">
                                                            <Chip color="warning">
                                                                {formatPrice(lot_amount_without_delivery)} (₽)
                                                            </Chip>
                                                        </Typography>
                                                    </Stack>
                                                    <Stack direction={"row"}>
                                                        <Typography level="body-md" sx={{ width: "100%" }}>Сумма партии (₽)</Typography>
                                                        <Typography level="title-md">
                                                            <Chip color="warning">
                                                                {formatPrice(batch_amount)} (₽)
                                                            </Chip>
                                                        </Typography>
                                                    </Stack>
                                                    <Stack direction={"row"}>
                                                        <Typography level="body-md" sx={{ width: "100%" }}>Закупка + Упаковка</Typography>
                                                        <Typography level="title-md">
                                                            <Chip color="warning">
                                                                {procurement_packaging}
                                                            </Chip>
                                                        </Typography>
                                                    </Stack>

                                                    <Stack direction={"row"}>
                                                        <Typography level="body-md" sx={{ width: "100%" }}>Сумма партии + Упаковка</Typography>
                                                        <Typography level="title-md">
                                                            <Chip color="warning">
                                                                {formatPrice(lot_amount_packaging)} (₽)
                                                            </Chip>
                                                        </Typography>
                                                    </Stack>
                                                    <Stack direction={"row"}>
                                                        <Typography level="body-md" sx={{ width: "100%" }}>Скидка продавца (%)</Typography>
                                                        <Typography level="title-md">
                                                            <Chip color="warning">
                                                                {discount}
                                                            </Chip>
                                                        </Typography>
                                                    </Stack>
                                                    <Stack direction={"row"}>
                                                        <Typography level="body-md" sx={{ width: "100%" }}>Объем упаковки, Литр</Typography>
                                                        <Typography level="title-md">
                                                            <Chip color="warning">
                                                                {packaging_volume}
                                                            </Chip>
                                                        </Typography>
                                                    </Stack>

                                                    <Stack direction={"row"}>
                                                        <Typography level="body-md" sx={{ width: "100%" }}>Базовая стоимость логистики</Typography>
                                                        <Typography level="title-md">
                                                            <Chip color="warning">
                                                                {basic_cost_of_logistics}
                                                            </Chip>
                                                        </Typography>
                                                    </Stack>
                                                    <Stack direction={"row"}>
                                                        <Typography level="body-md" sx={{ width: "100%" }}>Стоимость логистики с учетом выкупа</Typography>
                                                        <Typography level="title-md">
                                                            <Chip color="warning">
                                                                {cost_of_logistics_including_redemption}
                                                            </Chip>
                                                        </Typography>
                                                    </Stack>
                                                    <Stack direction={"row"}>
                                                        <Typography level="body-md" sx={{ width: "100%" }}>Стоимость возврата с учетом выкупа</Typography>
                                                        <Typography level="title-md">
                                                            <Chip color="warning">
                                                                {return_value_including_redemption}
                                                            </Chip>
                                                        </Typography>
                                                    </Stack>

                                                    <Stack direction={"row"}>
                                                        <Typography level="body-md" sx={{ width: "100%" }}>Итого доставка</Typography>
                                                        <Typography level="title-md">
                                                            <Chip color="warning">
                                                                {total_shipping}
                                                            </Chip>
                                                        </Typography>
                                                    </Stack>
                                                    <Stack direction={"row"}>
                                                        <Typography level="body-md" sx={{ width: "100%" }}>Налог с единицы товара (₽)</Typography>
                                                        <Typography level="title-md">
                                                            <Chip color="warning">
                                                                {formatPrice(tax_per_unit_of_goods)}
                                                            </Chip>
                                                        </Typography>
                                                    </Stack>
                                                    <Stack direction={"row"}>
                                                        <Typography level="body-md" sx={{ width: "100%" }}>Выручка за единицы товара</Typography>
                                                        <Typography level="title-md">
                                                            <Chip color="warning">
                                                                {formatPrice(revenue_per_unit_of_goods)}
                                                            </Chip>
                                                        </Typography>
                                                    </Stack>

                                                    <Stack direction={"row"}>
                                                        <Typography level="body-md" sx={{ width: "100%" }}>Выручка на партию</Typography>
                                                        <Typography level="title-md">
                                                            <Chip color="warning">
                                                                {formatPrice(revenue_per_batch)} (₽)
                                                            </Chip>
                                                        </Typography>
                                                    </Stack>
                                                    <Stack direction={"row"}>
                                                        <Typography level="body-md" sx={{ width: "100%" }}>Продаж в день</Typography>
                                                        <Typography level="title-md">
                                                            <Chip color="warning">
                                                                {sales_per_day}
                                                            </Chip>
                                                        </Typography>
                                                    </Stack>
                                                    <Stack direction={"row"}>
                                                        <Typography level="body-md" sx={{ width: "100%" }}>Хранение, дней</Typography>
                                                        <Typography level="title-md">
                                                            <Chip color="warning">
                                                                {storage}
                                                            </Chip>
                                                        </Typography>
                                                    </Stack>
                                                    <Stack direction={"row"}>
                                                        <Typography level="body-md" sx={{ width: "100%" }}>Стоимость хранения</Typography>
                                                        <Typography level="title-md">
                                                            <Chip color="warning">
                                                                {formatPrice(storage_cost)} (₽)
                                                            </Chip>
                                                        </Typography>
                                                    </Stack>
                                                </Stack>
                                            </Sheet>
                                        </Grid>
                                        <Button sx={{ my: 2 }} variant="solid" size="lg" color="success" type="submit">
                                            Сохранить
                                        </Button>
                                    </Grid>
                                </form>
                            </ModalDialog>
                        </ModalOverflow>
                    </Modal>
                )
            }
        </AnimatePresence>
    );
}