import { createContext, useEffect, useState } from "react";
import { APIGetUnitWarehouse, APIGetUser } from "./api";



export const LayoutContext = createContext();


export default function LayoutProvider({ children }) {
    const [ sidebarOpen, setSidebarOpen ] = useState(false);
    const [ account, setAccount ] = useState({});

    const [ modalRate, setModalRate ] = useState(false);
    const [ modalReg, setModalReg ] = useState(false);
    const [ modalSendEmail, setModalSendEmail ] = useState(false);
    const [ modalSuccessCheckEmail, setModalSuccessCheckEmail ] = useState(false);
    const [ modalResendEmail, setModalResendEmail ] = useState({ token: null, open: false });

    const [ modalSuccessPay, setModalSuccessPay ] = useState(false);


    const [ modalResetPassword, setModalResetPassword ] = useState(false);
    const [ modalSendEmailResetPassword, setModalSendEmailResetPassword ] = useState(false);
    const [ modalNewPassword, setModalNewPassword ] = useState({ token: null, open: false });
    

    const [ modalAuth, setModalAuth ] = useState(false);
    const [ modalLogout, setModalLogout ] = useState(false);
    const [ modalInfo, setModalInfo ] = useState({ info: undefined, open: false });
    const [ modalArticle, setModalArticle ] = useState({ item: undefined, open: false });
    const [ modalUnit, setModalUnit ] = useState(false);

    // Формат вывода денег
    function formatPrice(price) {
        return new Intl.NumberFormat('ru-RU').format(price);
    }

    useEffect(() => {
        APIGetUser().then((data) => {
            if (data?.status == 403) {
                console.log("Пользователь НЕ авторизован", data);
            }
            if (data?.id) {
                console.log("Пользователь авторизован", data);
                setAccount(data);
            }
        })
    }, []);


    const [ unit_warehouse, setUnit_warehouse ] = useState(); 
    function loadUnit() {
        APIGetUnitWarehouse().then((data) => {
            if (data) {
                setUnit_warehouse(data)
            }
        })
    }

    useEffect(() => {
        loadUnit();
    }, [account])
    useEffect(() => {
        loadUnit();
    }, [])


    const [ lock, setLock ] = useState(true);
    
    const values = {
        sidebarOpen, setSidebarOpen,
        formatPrice,
        account, setAccount,

        modalRate, setModalRate,
        modalReg, setModalReg,
        modalSendEmail, setModalSendEmail,

        modalSuccessPay, setModalSuccessPay,
        
        modalResendEmail, setModalResendEmail,
        modalSuccessCheckEmail, setModalSuccessCheckEmail,
        modalNewPassword, setModalNewPassword,

        modalSendEmailResetPassword, setModalSendEmailResetPassword,
        modalResetPassword, setModalResetPassword,
        modalAuth, setModalAuth,
        modalLogout, setModalLogout,
        modalInfo, setModalInfo,
        modalArticle, setModalArticle,
        modalUnit, setModalUnit,
        unit_warehouse,

        lock, setLock
    }
    
    return (
        <LayoutContext.Provider value={values}>
            {children}
        </LayoutContext.Provider>
    );
}