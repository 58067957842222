import { Box } from "@mui/joy";

import { Link as RouterLink, useNavigate, useParams } from "react-router-dom";
import { useContext, useEffect } from "react";
import { APICheckEmail } from "../api";
import { LayoutContext } from "../context";




export default function ActivatePage() {
    const navigate = useNavigate();
    const { token } = useParams(); // Извлекаем токен из URL
    const { setModalSuccessCheckEmail, modalResendEmail } = useContext(LayoutContext);
    

    useEffect(() => {
        // Отправляем токен на бэкенд
        APICheckEmail(token).then((data) => {
            if (data?.status == 'ok') {
                navigate("/");
                setModalSuccessCheckEmail(true);
            }
            else if (data?.response?.data?.status == 'notfound') {
                navigate("/");
                alert(data?.response?.data?.message);
            }
            else if (data?.response?.data?.status == 'timeout') {
                navigate("/");
                modalResendEmail({ open: true, token: token })
            }
        })
    }, [token]);

    return (
        <>
            <Box>
            </Box>
        </>
    );
}