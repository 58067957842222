import { Avatar, Box, Button, Chip, DialogContent, DialogTitle, FormControl, FormLabel, Grid, Input, Link, Modal, ModalClose, ModalDialog, ModalOverflow, Option, Select, Sheet, Stack, Typography } from "@mui/joy";
import { useContext, useEffect, useMemo, useState } from "react";
import { LayoutContext } from "../../context";
import { motion, AnimatePresence } from "framer-motion";
import InputMask from 'react-input-mask';
import { useLocation, useNavigate } from "react-router-dom";
import { APICreateUnit } from "../../api";
import ToolTipTable from "../TableUnitEconomic/ToolTipTable";

import InsertEmoticonIcon from '@mui/icons-material/InsertEmoticon';


export default function ModalSearchArticleItem() {
    const { modalArticle, setModalArticle, formatPrice, account, setAccount } = useContext(LayoutContext);

    const vkSearch = useMemo(() => {
        const elem = modalArticle?.item?.items_search_artic?.find(item1 => item1?.type_social_network == "vk")
        if ( elem != -1 ) {
            return elem;
        }
        return undefined
    }, [modalArticle]);


    return (
        <AnimatePresence>
            {
                modalArticle?.open && (
                    <Modal
                        open={modalArticle?.open}
                        onClose={() => setModalArticle({...modalArticle, open: false})}
                        slotProps={{
                            backdrop: {
                                component: motion.div,
                                initial: { opacity: 0, backdropFilter: 'blur(0px)' },
                                animate: { opacity: 1, backdropFilter: 'blur(8px)' },
                                exit: { opacity: 0, backdropFilter: 'blur(0px)' },
                                transition: { duration: 0.3, ease: "easeInOut" },
                            }
                        }}
                    >
                        <ModalOverflow sx={{ height: 'auto' }}>
                            <ModalDialog
                                size="md"
                                component={motion.div}
                                initial={{ opacity: 0, y: -10, scale: 0.98 }}
                                animate={{ opacity: 1, y: 0, scale: 1 }}
                                exit={{ opacity: 0, y: 10, scale: 0.98 }}
                                transition={{ duration: 0.2, ease: "easeInOut" }}
                            >
                                <ModalClose />
                                <Typography sx={{ textAlign: 'center', mb: 3, px: 5 }} level="h4">
                                    Поиск по ключу: {modalArticle?.item?.key}
                                </Typography>

                                <Typography level="h4">
                                    Вк:
                                </Typography>
                                <Sheet variant="soft" sx={{ px: 2, py: 3, borderRadius: 'xl' }}>
                                    <Stack direction={"column"} spacing={{ xs: 0.5, sm: 1 }}>
                                        { vkSearch?.find_data?.message ?
                                            <Box>
                                                <Typography level="body-sm">
                                                    Найдено в ВК:
                                                </Typography>
                                                <Typography level="title-sm" color="primary">
                                                    {vkSearch?.find_data?.message}
                                                </Typography>
                                            </Box>
                                            :
                                            vkSearch?.find_data?.map((item, index) => (
                                            <Link href={item?.link} target="_blank" rel="noreferrer" level="title-sm" sx={{ width: "100%" }}>
                                                <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                                                    { item?.photo ?
                                                        <Avatar alt={item?.name} src={item?.photo} />
                                                        :
                                                        <Sheet sx={{ p: 0.9, bgcolor: 'neutral.300', borderRadius: '50%', display: "flex" }}>
                                                            <InsertEmoticonIcon sx={{ fontSize: 'xl' }}/>
                                                        </Sheet>
                                                    }
                                                    <Box sx={{ minWidth: 0, flex: 1, display: 'flex', flexDirection: "column" }}>
                                                        <Typography level="title-sm">{item?.name}</Typography>
                                                        <Typography noWrap level="body-xs" sx={{ maxWidth: 300 }}>{item?.text}</Typography>
                                                    </Box>
                                                </Box>
                                            </Link>
                                        ))}
                                    </Stack>
                                </Sheet>
                                
                                
                                {/* <Grid container
                                    spacing={3}
                                    columns={{ xs: 4, sm: 8, smmd: 8, md: 16, mdlg: 6, lg: 16, lgxl: 16, xl: 16 }}
                                    sx={{ justifyContent: "center" }}
                                >
                                    <Grid xs={4} sm={8} smmd={8} md={8} mdlg={3} lg={8} lgxl={8} xl={8}>
                                        <Sheet variant="soft" sx={{ px: 2, py: 3, borderRadius: 'xl' }}>
                                            <Stack direction={"column"} spacing={{ xs: 0.5, sm: 1 }}>
                                                { vkSearch?.find_data?.map((item, index) => (
                                                    <Link href={item?.link} target="_blank" rel="noreferrer" level="title-sm" sx={{ width: "100%" }}>
                                                        <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                                                            <Sheet sx={{ p: 0.9, bgcolor: 'neutral.300', borderRadius: '50%', display: "flex" }}>
                                                                <InsertEmoticonIcon sx={{ fontSize: 'xl' }}/>
                                                            </Sheet>
                                                            <Box sx={{ minWidth: 0, flex: 1, display: 'flex', flexDirection: "column" }}>
                                                                <Typography level="title-sm">{item?.name}</Typography>
                                                                <Typography noWrap level="body-xs" sx={{ maxWidth: 300 }}>{item?.text}</Typography>
                                                            </Box>
                                                        </Box>
                                                    </Link>
                                                ))}
                                            </Stack>
                                        </Sheet>
                                    </Grid> */}

                                    {/* <Grid xs={4} sm={8} smmd={8} md={8} mdlg={3} lg={8} lgxl={8} xl={8}>
                                        <Sheet variant="soft" sx={{ px: 2, py: 3, borderRadius: 'xl' }}>
                                            <Stack direction={"column"} spacing={1}>
                                                <Stack direction={"row"}>
                                                    <Typography level="body-md" sx={{ width: "100%" }}>Сумма партии без доставки (₽)</Typography>
                                                    <Typography level="title-md">
                                                        <Chip color="warning">
                                                            {1} (₽)
                                                        </Chip>
                                                    </Typography>
                                                </Stack>
                                            </Stack>
                                        </Sheet>
                                    </Grid> */}
                                    {/* <Button sx={{ my: 2 }} variant="solid" size="lg" color="success" type="submit">
                                        Сохранить
                                    </Button> */}
                                {/* </Grid> */}
                            </ModalDialog>
                        </ModalOverflow>
                    </Modal>
                )
            }
        </AnimatePresence>
    );
}